export const data = {
  dataCode: 0,
  total: 247,
  isApple: false,
  isLaptop: false,
  brandAscii: null,
  brandName: null,
  filterString: '',
  headingString: '',
  brandId: null,
  rootCategory: {
    seoTitle: 'Laptop | Máy tính xách tay giá rẻ, mẫu mới nhất 2023',
    metaDescription:
      'Mua laptop giá rẻ, máy tính xách tay chính hãng 100%, luôn cập nhật mẫu mới. Bảo hành hậu mãi chu đáo, trả góp 0%, miễn phí giao hàng, đổi trả linh hoạt',
    metaKeyword: 'laptop, máy tính xách tay, laptop giá rẻ',
    name: 'Laptop',
    nameAscii: 'may-tinh-xach-tay',
    parentID: 0,
    order: 3,
    isShowInTab: true,
    isShowInNavFilter: false,
    imageCateUrl: '2022/11/7/638034393162095192_NF_1200x628-Laptop.jpg',
    shareImageUrl: '2022/11/7/638034393162095192_NF_1200x628-Laptop.jpg',
    id: 3,
  },
  firstCategory: null,
  navFilter: {
    rootCategoryID: 3,
    rootCategoryAscii: 'may-tinh-xach-tay',
    listCategory: [
      {
        seoTitle: 'Laptop | Máy tính xách tay giá rẻ, mẫu mới nhất 2023',
        metaDescription:
          'Mua laptop giá rẻ, máy tính xách tay chính hãng 100%, luôn cập nhật mẫu mới. Bảo hành hậu mãi chu đáo, trả góp 0%, miễn phí giao hàng, đổi trả linh hoạt',
        metaKeyword: 'laptop, máy tính xách tay, laptop giá rẻ',
        name: 'Laptop',
        nameAscii: 'may-tinh-xach-tay',
        parentID: 0,
        order: 3,
        isShowInTab: true,
        isShowInNavFilter: false,
        imageCateUrl: '2022/11/7/638034393162095192_NF_1200x628-Laptop.jpg',
        shareImageUrl: '2022/11/7/638034393162095192_NF_1200x628-Laptop.jpg',
        id: 3,
      },
      {
        seoTitle: 'Hãng sản xuất',
        metaDescription: 'Hãng sản xuất',
        metaKeyword: 'Hãng sản xuất',
        name: 'Hãng sản xuất',
        nameAscii: 'hang-san-xuat',
        parentID: 3,
        order: 1,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 299,
      },
      {
        seoTitle: 'Laptop Acer | Máy tính Acer chính hãng, trả góp 0%',
        metaDescription:
          'Mua laptop Acer giá rẻ, hỗ trợ trả góp 0% với nhiều ưu đãi hấp dẫn. Bảo hành chính hãng, giao nhanh trong 1H, cho phép đổi trả, thanh toán linh hoạt',
        metaKeyword: 'laptop acer, máy tính acer, máy tính xách tay acer',
        name: 'Acer',
        nameAscii: 'acer',
        parentID: 299,
        order: 2,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '2020/8/26/637340494666704995_Acer@2x.jpg',
        shareImageUrl: '',
        id: 300,
      },
      {
        seoTitle: 'Laptop Asus | Máy tính Asus chính hãng, trả góp 0%',
        metaDescription:
          'Mua laptop Asus, máy tính xách tay Asus giá rẻ, hỗ trợ trả góp 0% với nhiều quà tặng giá trị lên đến 2,5 triệu. Miễn phí giao nhanh trong 1H, bảo hành chính hãng',
        metaKeyword: 'asus, laptop asus, máy tính asus',
        name: 'Asus',
        nameAscii: 'asus',
        parentID: 299,
        order: -4,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '2021/11/22/637732077455069770_Asus@2x.jpg',
        shareImageUrl: '',
        id: 301,
      },
      {
        seoTitle: 'Laptop Dell | Mẫu mới nhất 2023, trả góp 0%',
        metaDescription:
          'Mua laptop Dell mẫu mã mới nhất 2023, phù hợp mọi đối tượng, thiết kế hiện đại. Bảo hành chính hãng, đổi trả linh hoạt, hỗ trợ trả góp 0%, giá tốt nhất',
        metaKeyword: 'laptop dell',
        name: 'Dell',
        nameAscii: 'dell',
        parentID: 299,
        order: 0,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '2020/8/26/637340494666861275_Dell@2x.jpg',
        shareImageUrl: '',
        id: 303,
      },
      {
        seoTitle: 'Laptop HP giá rẻ | Mẫu mới nhất 2023, hỗ trợ trả góp 0%',
        metaDescription:
          'Mua laptop HP giá siêu ưu đãi, hỗ trợ trả góp 0% xét duyệt nhanh, đổi trả linh hoạt. Sản phẩm các dòng HP 14s, HP 15s, HP Pavilion 15, HP Envy mới nhất 2023',
        metaKeyword: 'laptop hp, máy tính hp',
        name: 'HP',
        nameAscii: 'hp',
        parentID: 299,
        order: -3,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '2021/7/15/637619564183327279_HP@2x.png',
        shareImageUrl: '',
        id: 305,
      },
      {
        seoTitle: 'Laptop Lenovo | Máy tính Lenovo mẫu mới nhất, trả góp 0%',
        metaDescription:
          'Mua máy tính xách tay, laptop Lenovo giá rẻ, mẫu mới nhất 2023, hỗ trợ thu cũ đổi mới. Cam kết chính hãng, trả góp 0%, miễn phí giao hàng, bảo hành 2 năm',
        metaKeyword: 'laptop lenovo, máy tính lenovo, máy tính xách tay lenovo',
        name: 'Lenovo',
        nameAscii: 'lenovo',
        parentID: 299,
        order: -3,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '2020/8/26/637340494668267616_Lenovo@2x.jpg',
        shareImageUrl: '',
        id: 306,
      },
      {
        seoTitle: 'Mức giá',
        metaDescription: 'Mức giá',
        metaKeyword: 'Mức giá',
        name: 'Mức giá',
        nameAscii: 'muc-gia',
        parentID: 3,
        order: 2,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 326,
      },
      {
        seoTitle:
          'Laptop | Máy tính xách tay dưới 10 triệu, trả góp 0% - FPT Shop',
        metaDescription:
          'Laptop, máy tính xách tay dưới 10 triệu cấu hình cao, giá rẻ. Hỗ trợ trả góp 0% với nhiều quà tặng đi kèm, bảo hành chính hãng uy tín, giao hàng tận nơi.',
        metaKeyword: 'Laptop từ 5 đến 10 triệu',
        name: 'Từ 5 - 10 triệu',
        nameAscii: 'tu-5-10-trieu',
        parentID: 326,
        order: 1,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '2017/4/26/636288204550119016_5-den-10M.png',
        shareImageUrl: '',
        id: 329,
      },
      {
        seoTitle:
          'Laptop | Máy tính xách tay dưới 15 triệu, trả góp 0% - FPT Shop',
        metaDescription:
          'Laptop, máy tính xách tay dưới 15 triệu cấu hình cao cho gaming - đồ họa. Hỗ trợ trả góp 0%, giảm giá cực sốc cùng nhiều quà tặng kèm, bảo hành chính hãng uy tín, giao hàng tận nơi.',
        metaKeyword: 'Laptop từ 10 đến 15 triệu, Laptop dưới 15 triệu,',
        name: 'Từ 10 - 15 triệu',
        nameAscii: 'tu-10-15-trieu',
        parentID: 326,
        order: 2,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '2017/4/26/636288203389839204_tu-10-den-15M.png',
        shareImageUrl: '',
        id: 331,
      },
      {
        seoTitle:
          'Laptop, máy tính xách tay từ 15 đến 20 triệu, Trả góp 0% - FPT Shop',
        metaDescription:
          'Laptop, máy tính xách tay giá từ 15 đến 20 triệu với nhiều khuyến mãi đi kèm, bảo hành chính hãng, trả góp 0%, tặng PMH giá trị, giao hàng tận nơi.',
        metaKeyword: 'Laptop từ 15 dến 20 triệu, laptop dưới 20 triệu,',
        name: 'Từ 15 - 20 triệu',
        nameAscii: 'tu-15-20-trieu',
        parentID: 326,
        order: 3,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '2017/4/26/636288203390151206_tu-15-den-20M.png',
        shareImageUrl: '',
        id: 333,
      },
      {
        seoTitle:
          'Laptop, máy tính xách tay dưới 25 triệu, trả góp 0% - FPT Shop',
        metaDescription:
          'Laptop, máy tính xách tay dưới 25 triệu cấu hình khủng thích hợp chơi game, đồ họa. Hỗ trợ trả góp 0%, giảm giá cực sốc cùng nhiều quà tặng, phụ kiện đi kèm. Mua ngay tại FPT Shop.',
        metaKeyword: 'Laptop từ 20 đến 25 triệu, laptop dưới 25 triệu,',
        name: 'Từ 20 - 25 triệu',
        nameAscii: 'tu-20-25-trieu',
        parentID: 326,
        order: 4,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '2017/4/26/636288203390151206_tu-20-den-25M.png',
        shareImageUrl: '',
        id: 334,
      },
      {
        seoTitle:
          'Laptop, máy tính xách tay dưới 30 triệu, Trả góp 0% - FPT Shop',
        metaDescription:
          'Mua laptop, máy tính xách tay giá dưới 30 triệu cấu hình khủng cho gaming, đồ họa. Hỗ trợ trả góp 0%, giảm giá cực sốc với nhiều quà tặng đi kèm, bảo hành uy tín tại FPT Shop.',
        metaKeyword: 'Laptop từ 25 đến 30 triệu, laptop dưới 30 triệu,',
        name: 'Từ 25 - 30 triệu',
        nameAscii: 'tu-25-30-trieu',
        parentID: 326,
        order: 5,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '2017/4/26/636288203390151206_tu-25-den-30M.png',
        shareImageUrl: '',
        id: 335,
      },
      {
        seoTitle:
          'Laptop, máy tính xách tay trên 30 triệu, Trả góp 0% - FPT Shop',
        metaDescription:
          'Mua laptop, máy tính xách tay giá trên 30 triệu chính hãng từ các thương hiệu lớn. Hỗ trợ trả góp 0%, tặng balo cao cấp, chế độ bảo hành uy tín tại FPT Shop.',
        metaKeyword: 'Laptop trên 30 triệu',
        name: 'Trên 30 triệu',
        nameAscii: 'tren-30-trieu',
        parentID: 326,
        order: 6,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '2017/4/26/636288203389527202_tren-30m.png',
        shareImageUrl: '',
        id: 336,
      },
      {
        seoTitle: 'Truyền thống',
        metaDescription: 'Truyền thống',
        metaKeyword: 'Truyền thống',
        name: 'Truyền thống',
        nameAscii: 'truyen-thong',
        parentID: 337,
        order: 0,
        isShowInTab: false,
        isShowInNavFilter: false,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 338,
      },
      {
        seoTitle: 'Màn hình rời',
        metaDescription: 'Màn hình rời',
        metaKeyword: 'Màn hình rời',
        name: 'Màn hình rời',
        nameAscii: 'man-hinh-roi',
        parentID: 337,
        order: 1,
        isShowInTab: false,
        isShowInNavFilter: false,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 339,
      },
      {
        seoTitle: 'Xoay 360°',
        metaDescription: 'Xoay 360°',
        metaKeyword: 'Xoay 360°',
        name: 'Xoay 360°',
        nameAscii: 'xoay-360°',
        parentID: 337,
        order: 0,
        isShowInTab: false,
        isShowInNavFilter: false,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 340,
      },
      {
        seoTitle: 'Màn hình trượt',
        metaDescription: 'Màn hình trượt',
        metaKeyword: 'Màn hình trượt',
        name: 'Màn hình trượt',
        nameAscii: 'man-hinh-truot',
        parentID: 337,
        order: 0,
        isShowInTab: false,
        isShowInNavFilter: false,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 341,
      },
      {
        seoTitle: 'MacBook Pro | Air chính hãng - Giảm đến 5 triệu, trả góp 0%',
        metaDescription:
          'Mua máy tính MacBook Pro, MacBook Air giá rẻ, trả góp 0% xét duyệt nhanh, thu cũ đổi mới, bảo hành chính hãng Apple, giao hàng miễn phí tới 63 tỉnh thành tại FPT Shop.',
        metaKeyword: 'Macbook, Macbook pro, macbook air',
        name: 'Apple (MacBook)',
        nameAscii: 'apple-macbook',
        parentID: 299,
        order: -5,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '2022/1/4/637769104385571970_Macbook-Apple@2x.jpg',
        shareImageUrl: '',
        id: 344,
      },
      {
        seoTitle: 'RAM',
        metaDescription: 'RAM laptop',
        metaKeyword: 'RAM laptop',
        name: 'RAM',
        nameAscii: 'ram',
        parentID: 3,
        order: 5,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 348,
      },
      {
        seoTitle: 'Laptop, máy tính xách tay RAM 2GB giá tốt - FPT Shop',
        metaDescription:
          'Mua laptop, máy tính xách tay RAM 2GB giá rẻ phù hợp cho học sinh, sinh viên. Mua tại FPT Shop đảm bảo chất lượng chính hãng, nhiều ưu đãi đi kèm, giao hàng tận nơi miễn phí.',
        metaKeyword: 'laptop ram 2gb, máy tính xách tay ram 2gb,',
        name: '2 GB',
        nameAscii: '2-gb',
        parentID: 348,
        order: 0,
        isShowInTab: false,
        isShowInNavFilter: false,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 351,
      },
      {
        seoTitle:
          'Laptop, máy tính xách tay RAM 4GB giá rẻ, Trả góp 0% - FPT Shop',
        metaDescription:
          'Mua laptop, máy tính xách tay RAM 4GB giá rẻ, đa dạng RAM 4GB DDR3, DDR4, DDR2. Mua tại FPT Shop được giảm giá sốc, hỗ trợ trả góp 0% cùng nhiều quà tặng hấp dẫn, giao hàng tận nơi miễn phí.',
        metaKeyword: 'laptop ram 4gb, máy tính xách tay ram 4gb,',
        name: '4 GB',
        nameAscii: '4-gb',
        parentID: 348,
        order: 0,
        isShowInTab: false,
        isShowInNavFilter: false,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 353,
      },
      {
        seoTitle:
          'Laptop, máy tính xách tay RAM 8 GB giá rẻ, trả góp 0% - FPT Shop',
        metaDescription:
          'Mua laptop, máy tính xách tay RAM 8GB DDR4, DDR3 giá rẻ, hỗ trợ trả góp 0% xét duyệt nhanh chóng, giao hàng tận nơi miễn phí, bảo hành chính hãng uy tín tại FPT Shop.',
        metaKeyword: 'laptop ram 8gb, máy tính xách tay ram 8gb,',
        name: '8 GB',
        nameAscii: '8-gb',
        parentID: 348,
        order: 0,
        isShowInTab: false,
        isShowInNavFilter: false,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 355,
      },
      {
        seoTitle:
          'Laptop, máy tính xách tay RAM 16GB giá rẻ, Trả góp 0% - FPT Shop',
        metaDescription:
          'Mua laptop, máy tính xách tay RAM 16GB DDR4, DDR3 cấu hình kủng với giá rẻ, giảm giá sốc tại FPT Shop cùng nhiều quà tặng giá trị, hỗ trợ trả góp 0%, giao hàng tận nơi miễn phí.',
        metaKeyword: 'laptop ram 16gb, máy tính xách tay ram 16gb,',
        name: '16 GB',
        nameAscii: '16-gb',
        parentID: 348,
        order: 0,
        isShowInTab: false,
        isShowInNavFilter: false,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 356,
      },
      {
        seoTitle:
          'Laptop | Máy tính xách tay dưới 5 triệu chính hãng - FPT Shop',
        metaDescription:
          'Mua laptop dưới 5 triệu, máy tính xách tay giá rẻ phù hợp với học sinh, sinh viên với nhiều quà tặng hấp dẫn, chất lượng máy đảm bảo, chế độ bảo hành uy tín tại FPTShop.',
        metaKeyword: 'Laptop dưới 5 triệu',
        name: 'Dưới 5 triệu',
        nameAscii: 'duoi-5-trieu',
        parentID: 326,
        order: 0,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '2017/4/26/636288203389683203_duoi-5M.png',
        shareImageUrl: '',
        id: 461,
      },
      {
        seoTitle: 'Hệ điều hành',
        metaDescription: 'Hệ điều hành',
        metaKeyword: 'Hệ điều hành',
        name: 'Hệ điều hành',
        nameAscii: 'he-dieu-hanh',
        parentID: 3,
        order: 8,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 469,
      },
      {
        seoTitle: 'Tính năng nổi bật',
        metaDescription: 'Tính năng nổi bật',
        metaKeyword: 'Tính năng nổi bật',
        name: 'Tính năng nổi bật',
        nameAscii: 'tinh-nang-noi-bat',
        parentID: 3,
        order: 10,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 499,
      },
      {
        seoTitle: 'Âm thanh độc quyền Sonic Master',
        metaDescription: 'Âm thanh độc quyền Sonic Master',
        metaKeyword: 'Âm thanh độc quyền Sonic Master',
        name: 'Âm thanh độc quyền Sonic Master',
        nameAscii: 'am-thanh-doc-quyen-sonic-master',
        parentID: 515,
        order: 0,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 556,
      },
      {
        seoTitle: 'Bộ lưu trữ thể rắn flash nhanh chóng, đa nhiệm',
        metaDescription: 'Bộ lưu trữ thể rắn flash nhanh chóng, đa nhiệm',
        metaKeyword: 'Bộ lưu trữ thể rắn flash nhanh chóng, đa nhiệm',
        name: 'Bộ lưu trữ thể rắn flash nhanh chóng, đa nhiệm',
        nameAscii: 'bo-luu-tru-the-ran-flash-nhanh-chong-da-nhiem',
        parentID: 515,
        order: 0,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 557,
      },
      {
        seoTitle: 'Thời lượng Pin lâu 8-12 tiếng',
        metaDescription: 'Thời lượng Pin lâu 8-12 tiếng',
        metaKeyword: 'Thời lượng Pin lâu 8-12 tiếng',
        name: 'Thời lượng Pin lâu 8-12 tiếng',
        nameAscii: 'thoi-luong-pin-lau-8-12-tieng',
        parentID: 515,
        order: 0,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 559,
      },
      {
        seoTitle: 'Dễ sử dụng: không cần cài thêm',
        metaDescription: 'Dễ sử dụng: không cần cài thêm',
        metaKeyword: 'Dễ sử dụng: không cần cài thêm',
        name: 'Dễ sử dụng: không cần cài thêm',
        nameAscii: 'de-su-dung-khong-can-cai-them',
        parentID: 515,
        order: 0,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 561,
      },
      {
        seoTitle: 'Ổ cứng SSD',
        metaDescription: 'Ổ cứng SSD',
        metaKeyword: 'Ổ cứng SSD',
        name: 'Ổ cứng SSD',
        nameAscii: 'o-cung-ssd',
        parentID: 515,
        order: 0,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 562,
      },
      {
        seoTitle: 'VGA rời',
        metaDescription: 'VGA rời',
        metaKeyword: 'VGA rời',
        name: 'VGA rời',
        nameAscii: 'vga-roi',
        parentID: 515,
        order: 0,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 564,
      },
      {
        seoTitle: 'CPU-Quad Core for gaming',
        metaDescription: 'CPU-Quad Core for gaming',
        metaKeyword: 'CPU-Quad Core for gaming',
        name: 'CPU-Quad Core for gaming',
        nameAscii: 'cpu-quad-core-for-gaming',
        parentID: 515,
        order: 0,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 566,
      },
      {
        seoTitle: 'Có 3G',
        metaDescription: 'Có 3G',
        metaKeyword: 'Có 3G',
        name: 'Có 3G',
        nameAscii: 'co-3g',
        parentID: 515,
        order: 0,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 567,
      },
      {
        seoTitle: 'Khởi động máy nhanh',
        metaDescription: 'Khởi động máy nhanh',
        metaKeyword: 'Khởi động máy nhanh',
        name: 'Khởi động máy nhanh',
        nameAscii: 'khoi-dong-may-nhanh',
        parentID: 515,
        order: 0,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 569,
      },
      {
        seoTitle: 'Laptop MSI | Laptop gaming giá rẻ, cấu hình siêu mạnh',
        metaDescription:
          'Mua laptop MSI, máy tính chơi game giá rẻ, cấu hình khủng, hiệu năng chiến game thần tốc, làm việc hiệu quả. Giá giảm lớn, bảo hành chính hãng, trả góp 0%',
        metaKeyword: 'laptop msi, máy tính msi, laptop gaming',
        name: 'MSI',
        nameAscii: 'msi',
        parentID: 299,
        order: -2,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '2020/8/26/637340493755614653_MSI@2x.jpg',
        shareImageUrl: '',
        id: 1707,
      },
      {
        seoTitle: 'Laptop Sinh viên - Văn phòng, có hỗ trợ trả góp 0%',
        metaDescription:
          'Mua laptop sinh viên, phục vụ công việc văn phòng chính hãng, giá tốt ✓ Trả góp 0% thủ tục xét duyệt nhanh ✓ Bảo hành chính hãng ✓ Giao hàng tận nơi chỉ trong 1h ✓ Tư vấn tận tình, phục vụ tận tâm',
        metaKeyword: '',
        name: 'Sinh viên - Văn phòng',
        nameAscii: 'sinh-vien-van-phong',
        parentID: 1954,
        order: 0,
        isShowInTab: true,
        isShowInNavFilter: false,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1922,
      },
      {
        seoTitle: 'Laptop gaming chính hãng | Cấu hình khủng, giá rẻ nhất 2022',
        metaDescription:
          'Mua laptop gaming giá rẻ, máy tính đồ họa cấu hình khủng, tản nhiệt tốt nhất tại FPTShop ✓Mẫu mới nhất 2022 ✓Trả góp 0% xét duyệt nhanh ✓Bảo hành toàn quốc ✓Giao tận nơi trong 1h',
        metaKeyword:
          'Laptop gaming, Laptop gaming giá rẻ, Laptop đồ họa, laptop chơi game',
        name: 'Gaming - Đồ họa',
        nameAscii: 'gaming-do-hoa',
        parentID: 1954,
        order: 0,
        isShowInTab: true,
        isShowInNavFilter: false,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1924,
      },
      {
        seoTitle: 'Laptop doanh nhân, đẳng cấp, sang trọng',
        metaDescription:
          'Laptop doanh nhân có thiết kế tinh tế, cao cấp, cấu hình mạnh mẽ sẽ cùng bạn chinh phục những mục tiêu mới, bảo hành chính hãng, giao hàng chỉ trong 1h, trả góp 0% xét duyệt nhanh',
        metaKeyword: '',
        name: 'Doanh nhân',
        nameAscii: 'doanh-nhan',
        parentID: 1954,
        order: 0,
        isShowInTab: true,
        isShowInNavFilter: false,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1925,
      },
      {
        seoTitle: null,
        metaDescription: '',
        metaKeyword: '',
        name: 'Ram khủng',
        nameAscii: 'ram-khung',
        parentID: 1954,
        order: 0,
        isShowInTab: true,
        isShowInNavFilter: false,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1926,
      },
      {
        seoTitle: 'Laptop mỏng nhẹ, trả góp 0%, giao nhanh 1h',
        metaDescription:
          'Mua laptop siêu mỏng nhẹ chính hãng dễ dàng di chuyển, tiện dụng ✓ Trả góp 0% thủ tục xét duyệt nhanh ✓ Bảo hành chính hãng ✓ Giao hàng tận nơi chỉ trong 1h ✓ Tư vấn tận tình, phục vụ tận tâm',
        metaKeyword: '',
        name: 'Mỏng nhẹ',
        nameAscii: 'mong-nhe',
        parentID: 1954,
        order: 4,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1935,
      },
      {
        seoTitle: null,
        metaDescription: '',
        metaKeyword: '',
        name: 'CPU',
        nameAscii: 'cpu',
        parentID: 3,
        order: 4,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1950,
      },
      {
        seoTitle: null,
        metaDescription: '',
        metaKeyword: '',
        name: 'Loại Card đồ họa',
        nameAscii: 'loai-card-do-hoa',
        parentID: 3,
        order: 6,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1951,
      },
      {
        seoTitle: 'Laptop trang bị ổ cứng SSD',
        metaDescription:
          'Laptop trang bị ổ cứng SSD, mua hàng Online giá tốt tại FPT Shop ✓ Bảo hành chính hãng ✓ Giao hàng toàn quốc ✓ Cho phép đổi trả ✓ Trả góp: Thủ tục nhanh, lãi suất thấp',
        metaKeyword: '',
        name: 'Loại ổ cứng',
        nameAscii: 'loai-o-cung',
        parentID: 3,
        order: 7,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1952,
      },
      {
        seoTitle: null,
        metaDescription: '',
        metaKeyword: '',
        name: 'Màn hình',
        nameAscii: 'man-hinh',
        parentID: 3,
        order: 9,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1953,
      },
      {
        seoTitle: null,
        metaDescription: '',
        metaKeyword: '',
        name: 'Khác',
        nameAscii: 'khac',
        parentID: 3,
        order: 12,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1954,
      },
      {
        seoTitle:
          'Laptop, máy tính xách tay CPU Intel Celeron giá rẻ, Trả góp 0% - FPT Shop',
        metaDescription:
          'Laptop, máy tính xách tay CPU Intel Celeron nhiều thế hệ với các dòng Celeron n4000, n3350, g4900... Mua tại FPT Shop với giá rẻ kèm quà tặng giá trị, bảo hành chính hãng toàn quốc, hỗ trợ trả góp 0%.',
        metaKeyword:
          'laptop Intel Celeron, máy tính xách tay Intel Celeron, laptop cpu Intel Celeron, intel celeron n4000,',
        name: 'Intel Celeron',
        nameAscii: 'intel-celeron',
        parentID: 1950,
        order: 1,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1955,
      },
      {
        seoTitle:
          'Laptop, máy tính xách tay CPU Intel Pentium giá rẻ - FPT Shop',
        metaDescription:
          'Laptop, máy tính xách tay sử dụng CPU Intel Pentium các dòng Pentium Gold g5400, g4560, n4200, g2030... Mua tại FPT Shop với giá rẻ kèm quà tặng giá trị, hỗ trợ trả góp 0%, bảo hành chính hãng uy tín.',
        metaKeyword:
          'laptop Intel Pentium, máy tính xách tay Intel Pentium, laptop cpu Intel Pentium, intel Pentium Gold,',
        name: 'Intel Pentium',
        nameAscii: 'intel-pentium',
        parentID: 1950,
        order: 2,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1956,
      },
      {
        seoTitle:
          'Laptop, máy tính xách tay CPU Intel Core i3 giá rẻ - FPT Shop',
        metaDescription:
          'Laptop, máy tính xách tay dùng CPU Intel Core i3 các thế hệ i3 8100, 9100f, 7100, 6100... giá rẻ, hỗ trợ trả góp 0% xét duyệt nhanh, nhiều quà tặng giá trị, giao hàng tận nơi.',
        metaKeyword:
          'laptop core i3, máy tính xách tay core i3, intel core i3,',
        name: 'Intel Core i3',
        nameAscii: 'intel-core-i3',
        parentID: 1950,
        order: 3,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1957,
      },
      {
        seoTitle:
          'Laptop, máy tính xách tay CPU Intel Core i5 giá rẻ - FPT Shop',
        metaDescription:
          'Laptop, máy tính xách tay dùng CPU Intel Core i5 các thế hệ i5 9400f, i5 8400, 8250u, 8400, 4570.... giá rẻ, trả góp 0% cùng nhiều quà tặng giá trị, Windows bản quyền, giao hàng tận nơi miễn phí.',
        metaKeyword:
          'laptop core i5, máy tính xách tay core i5, laptop cpu intel core i5,',
        name: 'Intel Core i5',
        nameAscii: 'intel-core-i5',
        parentID: 1950,
        order: 4,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1958,
      },
      {
        seoTitle:
          'Laptop, máy tính xách tay CPU Intel Core i7 giá rẻ - FPT Shop',
        metaDescription:
          'Mua laptop, máy tính xách tay CPU Intel Core i7 giá rẻ, nhiều thế hệ core i7 8700, 8700k, i7 9700k, i7 3770,  i7 7700... Chế độ bảo hành chính hãng, quà tặng hấp dẫn, trả góp 0% tại FPT Shop.',
        metaKeyword:
          'laptop core i7, máy tính xách tay core i7, laptop cpu intel core i7,',
        name: 'Intel Core i7',
        nameAscii: 'intel-core-i7',
        parentID: 1950,
        order: 5,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1959,
      },
      {
        seoTitle:
          'Laptop VGA NVIDIA | Máy tính xách tay card màn hình NVIDIA Geforce - FPT Shop',
        metaDescription:
          'Laptop VGA NVIDIA, máy tính xách tay dùng Card màn hình (card đồ họa) NVIDIA dòng Geforce GTX 1050 ti, GT 730, 1030... Mua tại FPT Shop với giá rẻ, hỗ trợ trả góp 0%, bảo hành chính hãng toàn quốc.',
        metaKeyword: '',
        name: 'NVIDIA GeForce Series',
        nameAscii: 'nvidia-geforce-series',
        parentID: 1951,
        order: 1,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1960,
      },
      {
        seoTitle: 'Laptop AMD Ryzen chính hãng, giá rẻ, hỗ trợ trả góp 0% ',
        metaDescription:
          'Mua laptop chip AMD Ryzen, máy tính xách tay dùng Card màn hình (card đồ họa) AMD dòng Radeon 520, 530, hd 8790m, rx 580... Mua tại FPT Shop với giá rẻ, hỗ trợ trả góp 0%, bảo hành chính hãng, giao hàng tận nơi trên toàn quốc.',
        metaKeyword: '',
        name: 'AMD Series',
        nameAscii: 'amd-series',
        parentID: 1951,
        order: 2,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1961,
      },
      {
        seoTitle:
          'Laptop, máy tính xách tay card đồ họa Intel HD Graphics - FPT Shop',
        metaDescription:
          'Laptop VGA Intel, máy tính xách tay dùng Card màn hình (card đồ họa) Intel HD Graphics chính hãng. Mua tại FPT Shop với giá rẻ, hỗ trợ trả góp 0%, bảo hành chính hãng, giao hàng tận nơi trên toàn quốc.',
        metaKeyword: '',
        name: 'Intel HD Graphics',
        nameAscii: 'intel-hd-graphics',
        parentID: 1951,
        order: 3,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1962,
      },
      {
        seoTitle: 'Laptop trang bị ổ cứng HDD, trả góp 0%, giá tốt',
        metaDescription:
          'Mua laptop trang bị ổ cứng HDD chính hãng, giá tốt ✓ Trả góp 0% thủ tục xét duyệt nhanh ✓ Bảo hành chính hãng ✓ Giao hàng tận nơi chỉ trong 1h ✓ Tư vấn tận tình, phục vụ tận tâm',
        metaKeyword: '',
        name: 'HDD',
        nameAscii: 'hdd',
        parentID: 1952,
        order: 1,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1963,
      },
      {
        seoTitle: 'Laptop ổ cứng SSD giá tốt, hỗ trợ trả góp 0%',
        metaDescription:
          'Laptop ổ cứng SSD chính hãng, mua hàng Online giá tốt tại FPT Shop ✓ Bảo hành chính hãng ✓ Giao hàng toàn quốc ✓ Cho phép đổi trả ✓ Trả góp: Thủ tục nhanh, lãi suất thấp',
        metaKeyword: '',
        name: 'SSD',
        nameAscii: 'ssd',
        parentID: 1952,
        order: 2,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1964,
      },
      {
        seoTitle: 'Laptop ổ cứng HDD và SSD song song, trả góp 0%',
        metaDescription:
          'Mua laptop trang bị ổ cứng HDD và SSD song song, giá tốt ✓ Trả góp 0% thủ tục xét duyệt nhanh ✓ Bảo hành chính hãng ✓ Giao hàng tận nơi chỉ trong 1h ✓ Tư vấn tận tình, phục vụ tận tâm',
        metaKeyword: '',
        name: 'HDD + SSD',
        nameAscii: 'hdd-ssd',
        parentID: 1952,
        order: 3,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1965,
      },
      {
        seoTitle:
          'Laptop 11 inch | Máy tính xách tay 11 inch mỏng nhẹ, giá rẻ - FPT Shop',
        metaDescription:
          'Mua laptop, máy tính xách tay màn hình 11 inch, 11.6 inch mỏng nhẹ, cấu hình mạnh tại FPT Shop với giá rẻ, quà tặng hấp dẫn. Hỗ trợ trả góp 0% xét duyệt nhanh. Giao hàng tận nơi miễn phí.',
        metaKeyword: '',
        name: 'Khoảng 11 inchs',
        nameAscii: 'khoang-11-inchs',
        parentID: 1953,
        order: 1,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1966,
      },
      {
        seoTitle:
          'Laptop 12 inch | Máy tính xách tay 12 inch tốt nhất - FPT Shop',
        metaDescription:
          'Mua laptop, máy tính xách tay màn hình 12 inch, 12.5 inch mỏng nhẹ, cấu hình mạnh tại FPT Shop với giá rẻ, quà tặng hấp dẫn. Hỗ trợ trả góp 0% xét duyệt nhanh. Giao hàng tận nơi miễn phí.',
        metaKeyword: '',
        name: 'Khoảng 12 inchs',
        nameAscii: 'khoang-12-inchs',
        parentID: 1953,
        order: 2,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1967,
      },
      {
        seoTitle:
          'Laptop 13 inch | Máy tính xách tay 13 inch mỏng nhẹ, giá rẻ - FPT Shop',
        metaDescription:
          'Mua laptop, máy tính xách tay màn hình 13 inch, 13.3 inch mỏng nhẹ, cấu hình mạnh tại FPT Shop với giá rẻ, quà tặng hấp dẫn. Hỗ trợ trả góp 0% xét duyệt nhanh. Giao hàng tận nơi miễn phí.',
        metaKeyword:
          'laptop màn hình 13 inch, laptop 13 inch, máy tính xách tay màn hình 13 inch,',
        name: 'Khoảng 13 inchs',
        nameAscii: 'khoang-13-inchs',
        parentID: 1953,
        order: 3,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1968,
      },
      {
        seoTitle:
          'Laptop 14 inch | Máy tính xách tay 14 inch tốt nhất - FPT Shop',
        metaDescription:
          'Mua laptop, máy tính xách tay màn hình 14 inch mỏng nhẹ, Full HD tại FPT Shop với giá rẻ, nhiều quà tặng giá trị. Hỗ trợ trả góp 0% xét duyệt nhanh. Giao hàng tận nơi miễn phí.',
        metaKeyword:
          'laptop màn hình 14 inch, laptop 14 inch, máy tính xách tay màn hình 14 inch,',
        name: 'Khoảng 14 inchs',
        nameAscii: 'khoang-14-inchs',
        parentID: 1953,
        order: 4,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1969,
      },
      {
        seoTitle:
          'Laptop 15 inch | Máy tính xách tay 15 inch mỏng nhẹ, giá rẻ - FPT Shop',
        metaDescription:
          'Mua laptop, máy tính xách tay màn hình 15 inch mỏng nhẹ, cấu hình mạnh tại FPT Shop với giá rẻ, quà tặng hấp dẫn. Hỗ trợ trả góp 0% xét duyệt nhanh. Giao hàng tận nơi miễn phí.',
        metaKeyword: '',
        name: 'Khoảng 15 inchs',
        nameAscii: 'khoang-15-inchs',
        parentID: 1953,
        order: 5,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1970,
      },
      {
        seoTitle:
          'Laptop 17 inch | Máy tính xách tay màn hình 17 inch giá rẻ - FPT Shop',
        metaDescription:
          'Laptop, máy tính xách tay màn hình 17 inch thích hợp cho gaming, đồ họa. Mua tại FPT Shop với giá rẻ, trả góp 0% thủ tục xét duyệt nhanh, bảo hành chính hãng, giao hàng tận nơi miễn phí.',
        metaKeyword: '',
        name: 'Khoảng 17 inchs',
        nameAscii: 'khoang-17-inchs',
        parentID: 1953,
        order: 6,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1971,
      },
      {
        seoTitle: 'Laptop Windows bản quyền, trả góp 0% xét duyệt nhanh chóng',
        metaDescription:
          'Mua laptop chính hãng trang bị hệ điều hành Windows bản quyền, chính hãng ✓ Trả góp 0% thủ tục xét duyệt nhanh ✓ Bảo hành chính hãng ✓ Giao hàng tận nơi chỉ trong 1h ✓ Tư vấn tận tình, phục vụ tận tâm',
        metaKeyword: '',
        name: 'Windows',
        nameAscii: 'windows',
        parentID: 469,
        order: 1,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1972,
      },
      {
        seoTitle: 'Laptop hệ điều hành macOS, trả góp 0% xét duyệt nhanh',
        metaDescription:
          'Mua laptop chính hãng Apple trang bị hệ điều hành macOS ✓ Trả góp 0% thủ tục xét duyệt nhanh ✓ Bảo hành chính hãng từ Apple ✓ Giao hàng tận nơi chỉ trong 1h ✓ Tư vấn tận tình, phục vụ tận tâm',
        metaKeyword: '',
        name: 'macOS',
        nameAscii: 'macos',
        parentID: 469,
        order: 2,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1973,
      },
      {
        seoTitle: null,
        metaDescription: '',
        metaKeyword: '',
        name: 'Dos',
        nameAscii: 'dos',
        parentID: 469,
        order: 3,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1974,
      },
      {
        seoTitle: 'Laptop màn hình cảm ứng | Mẫu mới nhất 2022, trả góp 0%',
        metaDescription:
          'Mua laptop màn hình cảm ứng với thiết kế hiện đại, tiện lợi cùng cấu hình mượt mà. Cam kết chính hãng, trả góp 0% thủ tục xét duyệt nhanh, giao nhanh miễn phí trong 1h',
        metaKeyword: '',
        name: 'Màn hình cảm ứng',
        nameAscii: 'man-hinh-cam-ung',
        parentID: 499,
        order: 1,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1975,
      },
      {
        seoTitle: 'Laptop gập xoay 360 độ, trả góp 0%, giá tốt',
        metaDescription:
          'Mua laptop màn hình gập xoay 360 độ độc đáo, giá tốt, chính hãng ✓ Trả góp 0% thủ tục xét duyệt nhanh ✓ Bảo hành chính hãng ✓ Giao hàng tận nơi chỉ trong 1h ✓ Tư vấn tận tình, phục vụ tận tâm',
        metaKeyword: '',
        name: 'Xoay 360 độ',
        nameAscii: 'xoay-360-do',
        parentID: 499,
        order: 2,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1976,
      },
      {
        seoTitle: 'Laptop có thời lượng pin dài, lâu - Trả góp 0%',
        metaDescription:
          'Mua laptop pin sử dụng lâu, chính hãng, giá tốt ✓ Trả góp 0% thủ tục xét duyệt nhanh ✓ Bảo hành chính hãng ✓ Giao hàng tận nơi chỉ trong 1h ✓ Tư vấn tận tình, phục vụ tận tâm',
        metaKeyword: '',
        name: 'Thời lượng pin lâu',
        nameAscii: 'thoi-luong-pin-lau',
        parentID: 499,
        order: 3,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1977,
      },
      {
        seoTitle: 'Laptop Card đồ họa khủng, giá tốt, trả góp 0%',
        metaDescription:
          'Mua laptop Card đồ họa khủng, chiến game, làm đồ họa mượt mà ✓ Trả góp 0% thủ tục xét duyệt nhanh ✓ Bảo hành chính hãng ✓ Giao hàng tận nơi chỉ trong 1h',
        metaKeyword: '',
        name: 'Card đồ họa khủng',
        nameAscii: 'card-do-hoa-khung',
        parentID: 499,
        order: 5,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1978,
      },
      {
        seoTitle:
          'Laptop, máy tính xách tay CPU Intel Core M giá rẻ - FPT Shop',
        metaDescription:
          'Laptop, máy tính xách tay CPU Intel Core M chính hãng, giá tốt nhất tại FPT Shop. ✓Bảo hành chính hãng toàn quốc ✓Hỗ trợ trả góp 0% ✓Giao hàng tận nơi miễn phí.',
        metaKeyword: '',
        name: 'Intel Core M',
        nameAscii: 'intel-core-m',
        parentID: 1950,
        order: 7,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1979,
      },
      {
        seoTitle: 'Laptop bảo mật vân tay | Cam kết chính hãng, trả góp 0%',
        metaDescription:
          'Mua laptop trang bị bảo mật vân tay cao cấp, 1 chạm mở khóa tiện dụng. Trả góp 0% thủ tục xét duyệt nhanh, bảo hành chính hãng, giao nhanh tận nơi chỉ trong 1h',
        metaKeyword: '',
        name: 'Bảo mật vân tay',
        nameAscii: 'bao-mat-van-tay',
        parentID: 499,
        order: 6,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1980,
      },
      {
        seoTitle:
          'Laptop, máy tính xách tay CPU Intel Atom chính hãng, Trả góp 0% - FPT Shop',
        metaDescription:
          'Mua laptop, máy tính xách tay sử dụng CPU Intel Atom các dòng Atom X5-Z8350, Atom C2538, Z3580... tại FPT Shop với giá rẻ, bảo hành chính hãng uy tín, trả góp 0% cùng nhiều quà tặng hấp dẫn.',
        metaKeyword: '',
        name: 'Intel Atom',
        nameAscii: 'intel-atom',
        parentID: 1950,
        order: 0,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 1981,
      },
      {
        seoTitle: 'Laptop trả góp 0%/ 0 đồng | Xét duyệt nhanh, nhiều ưu đãi',
        metaDescription:
          'Mua máy tính xách tay trả góp thủ tục xét duyệt nhanh tại FPT Shop. Bảo hành chính hãng, giảm giá cực sốc kèm quà tặng giá trị, giao hàng tận nơi miễn phí',
        metaKeyword: '',
        name: 'Trả góp 0% và 0đ',
        nameAscii: 'tra-gop-00d',
        parentID: 2046,
        order: 10,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 2035,
      },
      {
        seoTitle: 'Laptop trả góp 0% | Hỗ trợ xét duyệt nhanh',
        metaDescription:
          'Mua laptop hỗ trợ trả góp 0% thủ tục đơn giản, xét duyệt nhanh gọn. Bảo hành chính hãng toàn quốc, giao hàng tận nơi miễn phí, giá tốt nhất, nhiều ưu đãi',
        metaKeyword: '',
        name: 'Trả góp 0%',
        nameAscii: 'tra-gop-0',
        parentID: 2046,
        order: 0,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 2036,
      },
      {
        seoTitle: 'Laptop trả góp 0 đồng | Hỗ trợ duyệt nhanh qua điện thoại',
        metaDescription:
          'Mua laptop trả góp 0 đồng thủ tục xét duyệt nhanh. Giá tốt nhất, bảo hành chính hãng uy tín, miễn phí giao nhanh trong 1H, chính sách đổi trả linh hoạt',
        metaKeyword: '',
        name: 'Trả góp 0Đ',
        nameAscii: 'tra-gop-0d',
        parentID: 2046,
        order: 0,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 2037,
      },
      {
        seoTitle: null,
        metaDescription: '',
        metaKeyword: '',
        name: 'Windows',
        nameAscii: 'windows',
        parentID: 1954,
        order: 0,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 2041,
      },
      {
        seoTitle:
          'Laptop, máy tính xách tay trả góp ưu đãi, giá tốt - FPT Shop',
        metaDescription:
          'Mua laptop trả góp, máy tính xách tay hỗ trợ trả góp ưu đãi, trả góp 0%, 0đ thủ tục xét duyệt nhanh tại FPT Shop. Bảo hành chính hãng, giảm giá cực sốc kèm quà tặng giá trị, giao hàng tận nơi miễn phí.',
        metaKeyword:
          'laptop trả góp, máy tính xách tay trả góp, mua laptop trả góp,',
        name: 'Trả góp ưu đãi',
        nameAscii: 'tra-gop-uu-dai',
        parentID: 3,
        order: 11,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 2046,
      },
      {
        seoTitle: 'Laptop Masstel | Máy tính học online giá siêu rẻ',
        metaDescription:
          'Mua máy tính học online, laptop Masstel bảo vệ trẻ khỏi những thông tin độc hại, phụ huynh dễ dàng kiểm soát. Cam kết chính hãng, trả góp 0%, giao hành trong 1H',
        metaKeyword: 'laptop masstel, máy tính xách tay masstel',
        name: 'Masstel',
        nameAscii: 'masstel',
        parentID: 299,
        order: 3,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '2020/8/26/637340491898901930_Masstel@2x.jpg',
        shareImageUrl: '',
        id: 2059,
      },
      {
        seoTitle: 'Laptop hiện đại, trả góp 0%, giao nhanh 1h',
        metaDescription:
          'Mua laptop hiện đại với kiểu dáng và công nghệ tích hợp mới nhất, trả góp 0% thủ tục xét duyệt nhanh ✓ Bảo hành chính hãng ✓ Giao hàng tận nơi chỉ trong 1h ✓ Tư vấn tận tình, phục vụ tận tâm',
        metaKeyword: '',
        name: 'Hiện đại',
        nameAscii: 'hien-dai',
        parentID: 1954,
        order: 0,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 2084,
      },
      {
        seoTitle: 'Laptop giá rẻ, trả góp 0%, giao nhanh 1h',
        metaDescription:
          'Mua laptop giá rẻ chính hãng, trả góp 0% thủ tục xét duyệt nhanh ✓ Bảo hành chính hãng ✓ Giao hàng tận nơi chỉ trong 1h ✓ Tư vấn tận tình, phục vụ tận tâm ',
        metaKeyword: '',
        name: 'Giá rẻ',
        nameAscii: 'gia-re',
        parentID: 1954,
        order: 0,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 2114,
      },
      {
        seoTitle:
          'Laptop Haier | Máy tính xách tay Haier chính hãng, Trả góp 0 đồng',
        metaDescription:
          'Laptop Haier, máy tính xách tay Haier chính hãng, giá rẻ cùng nhiều quà hấp dẫn tại FPT Shop. Bảo hành chính hãng uy tín, trả góp 0% hoặc 0 đồng, giao hành nhanh tận nhà.',
        metaKeyword: 'Laptop Haier, máy tính Haier,',
        name: 'Haier',
        nameAscii: 'haier',
        parentID: 299,
        order: 10,
        isShowInTab: false,
        isShowInNavFilter: false,
        imageCateUrl: '2018/10/8/636745937170340775_haier.gif',
        shareImageUrl: '',
        id: 2116,
      },
      {
        seoTitle: null,
        metaDescription: '',
        metaKeyword: '',
        name: 'ENVY',
        nameAscii: 'envy',
        parentID: 2157,
        order: 3,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 2152,
      },
      {
        seoTitle: null,
        metaDescription: '',
        metaKeyword: '',
        name: 'ProBook',
        nameAscii: 'probook',
        parentID: 2157,
        order: 4,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 2153,
      },
      {
        seoTitle: null,
        metaDescription: '',
        metaKeyword: '',
        name: 'Pavilon',
        nameAscii: 'pavilon',
        parentID: 2157,
        order: 1,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 2154,
      },
      {
        seoTitle: null,
        metaDescription: '',
        metaKeyword: '',
        name: 'Dòng sản phẩm',
        nameAscii: 'dong-san-pham',
        parentID: 3,
        order: 0,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 2157,
      },
      {
        seoTitle: null,
        metaDescription: '',
        metaKeyword: '',
        name: 'HP 15',
        nameAscii: 'hp-15',
        parentID: 2157,
        order: 0,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 2158,
      },
      {
        seoTitle: null,
        metaDescription: '',
        metaKeyword: '',
        name: '2 in 1',
        nameAscii: '2-in-1',
        parentID: 2157,
        order: 2,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 2159,
      },
      {
        seoTitle: 'Laptop, máy tính xách tay CPU AMD Ryzen 7 giá rẻ - FPT Shop',
        metaDescription:
          'Laptop, máy tính xách tay CPU AMD Ryzen 7 các dòng Ryzen 7 3700x, 2700x, 1800x, 1700x... Mua tại FPT Shop với giá tốt, hỗ trợ trả góp 0% cùng quà tặng hấp dẫn, bảo hành chính hãng, giao hàng tận nơi miễn phí.',
        metaKeyword: '',
        name: 'AMD Ryzen 7',
        nameAscii: 'amd-ryzen-7',
        parentID: 1950,
        order: 10,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 2160,
      },
      {
        seoTitle: 'Laptop, máy tính xách tay CPU AMD Ryzen 5 - FPT Shop',
        metaDescription:
          'Laptop, máy tính xách tay CPU AMD Ryzen 5 các dòng Ryzen 5 2400g, ryzen 5 3600, 2600, 1600... Mua tại FPT Shop với giá tốt, hỗ trợ trả góp 0% cùng quà tặng hấp dẫn, bảo hành chính hãng, giao hàng tận nơi miễn phí.',
        metaKeyword: '',
        name: 'AMD Ryzen 5',
        nameAscii: 'amd-ryzen-5',
        parentID: 1950,
        order: 9,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 2166,
      },
      {
        seoTitle: 'Laptop, máy tính xách tay CPU AMD Ryzen 3 giá rẻ - FPT Shop',
        metaDescription:
          'Laptop, máy tính xách tay CPU AMD Ryzen 3 các dòng Ryzen 3 2200g, Ryzen 3 1200, Ryzen 3 1300x... Mua tại FPT Shop với giá tốt, hỗ trợ trả góp 0% cùng quà tặng hấp dẫn, bảo hành chính hãng, giao hàng tận nơi miễn phí.',
        metaKeyword: '',
        name: 'AMD Ryzen 3',
        nameAscii: 'amd-ryzen-3',
        parentID: 1950,
        order: 8,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 2171,
      },
      {
        seoTitle: 'Laptop khuyến mại sốc, chính hãng, giá tốt',
        metaDescription:
          'Mua laptop khuyến mại hấp dẫn, chính hãng Dell, Asus, Acer, Lenovo…, có hỗ trợ trả góp 0% xét duyệt nhanh, tặng kèm balo chất lượng, giao hàng chỉ trong 1h',
        metaKeyword: '',
        name: 'Khuyến mại sốc',
        nameAscii: 'khuyen-mai-soc',
        parentID: 1954,
        order: 0,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 2175,
      },
      {
        seoTitle:
          'Laptop, máy tính xách tay CPU Intel Core i9 giá rẻ, Trả góp 0% - FPT Shop',
        metaDescription:
          'Laptop, máy tính xách tay CPU Intel Core i9 giá rẻ, nhiều thế hệ i9 9900k, i9 9980hk, i9 7900x... Mua tại FPT Shop được giảm giá sốc với nhiều quà tặng giá trị, trả góp 0%, bảo hành chính hãng trên toàn quốc.',
        metaKeyword:
          'laptop core i9, máy tính xách tay core i9, laptop cpu intel core i9,',
        name: 'Intel Core i9',
        nameAscii: 'intel-core-i9',
        parentID: 1950,
        order: 6,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 2201,
      },
      {
        seoTitle:
          'Laptop, máy tính xách tay RAM 32GB giá rẻ, Trả góp 0% - FPT Shop',
        metaDescription:
          'Mua laptop, máy tính xách tay RAM 32GB cấu hình khủng, hiệu năng cao với mức giá tốt nhất tại FPT Shop. Hỗ trợ trả góp 0% xét duyệt dễ dàng, nhanh chóng, quà tặng hấp dẫn, giao hàng tận nơi.',
        metaKeyword: 'laptop ram 32gb, máy tính xách tay ram 32gb,',
        name: '32 GB',
        nameAscii: '32-gb',
        parentID: 348,
        order: 0,
        isShowInTab: false,
        isShowInNavFilter: false,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 2202,
      },
      {
        seoTitle:
          'Laptop, máy tính xách tay card đồ họa Intel Iris Plus - FPT Shop',
        metaDescription:
          'Laptop VGA Intel, máy tính xách tay dùng Card màn hình (card đồ họa) Intel Iris Plus chính hãng. Mua tại FPT Shop với giá rẻ, hỗ trợ trả góp 0%, bảo hành chính hãng, giao hàng tận nơi trên toàn quốc.',
        metaKeyword: '',
        name: 'Intel Iris Plus',
        nameAscii: 'intel-iris-plus',
        parentID: 1951,
        order: 4,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 2237,
      },
      {
        seoTitle:
          'Microsoft Surface | Laptop Surface mẫu mới 2023, giá tốt nhất',
        metaDescription:
          'Mua laptop Surface, máy tính Microsoft Surface Pro, Surface Go giá rẻ, bảo hành chính hãng trên toàn quốc, miễn phí giao hàng, hỗ trợ đổi trả nhanh chóng',
        metaKeyword: 'microsoft surface, laptop surface',
        name: 'Microsoft (Surface)',
        nameAscii: 'microsoft',
        parentID: 299,
        order: 4,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '2021/1/13/637461259692529909_Microsoft@2x.png',
        shareImageUrl: '',
        id: 2351,
      },
      {
        seoTitle: 'Laptop AVITA Liber | Siêu gọn nhẹ, trả góp 0% duyệt nhanh',
        metaDescription:
          'Mua laptop AVITA Liber nhiều lựa chọn màu sắc, cân mọi tác vụ văn phòng, học tập. Hỗ trợ trả góp 0%, miễn phí giao hàng khi đặt online, đổi trả linh hoạt',
        metaKeyword: '',
        name: 'Avita',
        nameAscii: 'avita',
        parentID: 299,
        order: 7,
        isShowInTab: false,
        isShowInNavFilter: false,
        imageCateUrl: '2021/3/11/637510714785478525_Avita@2x.png',
        shareImageUrl: '',
        id: 2374,
      },
      {
        seoTitle: 'Laptop Gigabyte | Máy tính Gaming giá rẻ, trả góp 0%',
        metaDescription:
          'Mua laptop gaming Gigabyte thiết kế cao cấp, hoạt động bền bỉ, tản nhiệt tốt, chiến tốt mọi tựa game. Trả góp 0%, bảo hành chính hãng, giao nhanh trong 1H',
        metaKeyword: 'laptop gigabyte, laptop gaming',
        name: 'Gigabyte',
        nameAscii: 'gigabyte',
        parentID: 299,
        order: -1,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '2021/9/16/637674058450623615_Gigabyte@2x.jpg',
        shareImageUrl: '',
        id: 2555,
      },
      {
        seoTitle: null,
        metaDescription: '',
        metaKeyword: '',
        name: 'AMD Ryzen 9',
        nameAscii: 'amd-ryzen-9',
        parentID: 1950,
        order: 11,
        isShowInTab: false,
        isShowInNavFilter: true,
        imageCateUrl: '',
        shareImageUrl: '',
        id: 2561,
      },
      {
        seoTitle: 'Laptop Chuwi | Giá rẻ dưới 10 triệu, thiết kế đẹp, hiện đại',
        metaDescription:
          'Mua laptop Chuwi chính hãng 100%, giá siêu rẻ, sở hữu thiết kế đẹp, mỏng nhẹ. Trả góp 0%, bảo hành chính hãng, giao nhanh trong 1H, đổi trả linh hoạt',
        metaKeyword: 'máy tính chuwi, laptop chuwi',
        name: 'Chuwi',
        nameAscii: 'chuwi',
        parentID: 299,
        order: 6,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '2021/12/11/637748124762643705_Chuwi@2x.jpg',
        shareImageUrl: '',
        id: 2610,
      },
      {
        seoTitle: 'Laptop LG | Mẫu mới nhất 2023, hỗ trợ trả góp 0%',
        metaDescription:
          'Mua máy tính xách tay LG, laptop LG Gram thiết kế mỏng nhẹ, hiệu năng tốt cho mọi nhu cầu. Cam kết chính hãng, bảo hành 12 tháng, mua online giao nhanh trong 1H',
        metaKeyword: 'laptop LG',
        name: 'LG',
        nameAscii: 'lg',
        parentID: 299,
        order: 9,
        isShowInTab: true,
        isShowInNavFilter: true,
        imageCateUrl: '2022/9/14/637987475787766575_LG.jpg',
        shareImageUrl: '',
        id: 2728,
      },
    ],
  },
  listDefault: {
    brandId: null,
    pageSize: 0,
    categoryID: 3,
    categoryName: 'Laptop',
    availableProduct: true,
    total: 247,
    totalCurrent: 0,
    list: [
      {
        labelID: 0,
        brandID: 223,
        brandName: 'Apple (MacBook)',
        pictureID: 0,
        productTypeID: 3,
        name: 'MacBook Pro 14" 2021 M1 Pro 1TB',
        nameAscii: 'macbook-pro-14-2021-m1-pro-1tb',
        code: null,
        includeInfo: null,
        sizeWidth: 2219500.0,
        isHot: false,
        urlPicture:
          '2021/10/20/637703173012881570_macbook-pro-14-2021-bac-dd.jpg',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 15.000.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00777788',
          isRecurring: true,
          stockQuantity: 124,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 50990000.0,
          priceBeforeTax: 0.0,
          priceOnline: 0.0,
          priceMarket: 65990000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 646153,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: null,
        displayType: null,
        saleActiveDate: '2023-04-10T00:00:00',
        isUseLinkNew: true,
        storageAscii: '1tb',
        nameGroup: 'MacBook Pro 14" 2021 M1 Pro',
        nameAsciiGroup: 'macbook-pro-14-2021-m1-pro',
        nameParamAscii: 'dung-luong',
        id: 38369,
      },
      {
        labelID: 0,
        brandID: 223,
        brandName: 'Apple (MacBook)',
        pictureID: 0,
        productTypeID: 3,
        name: 'MacBook Pro 16" 2021 M1 Pro 1TB',
        nameAscii: 'macbook-pro-16-2021-m1-pro-1tb',
        code: null,
        includeInfo: null,
        sizeWidth: 4625500.0,
        isHot: false,
        urlPicture:
          '2021/10/20/637703174145702717_macbook-pro-16-2021-bac-dd.jpg',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 14.000.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00777785',
          isRecurring: true,
          stockQuantity: 27,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 56990000.0,
          priceBeforeTax: 0.0,
          priceOnline: 70990000.0,
          priceMarket: 70990000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 646293,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: null,
        displayType: null,
        saleActiveDate: '2023-04-10T00:00:00',
        isUseLinkNew: true,
        storageAscii: '1tb',
        nameGroup: 'MacBook Pro 16" 2021 M1 Pro',
        nameAsciiGroup: 'macbook-pro-16-2021-m1-pro',
        nameParamAscii: 'dung-luong',
        id: 38365,
      },
      {
        labelID: 0,
        brandID: 223,
        brandName: 'Apple (MacBook)',
        pictureID: 0,
        productTypeID: 3,
        name: 'MacBook Pro 16" 2021 M1 Pro Ram 32GB',
        nameAscii: 'macbook-pro-16-2021-m1-pro-ram-32gb',
        code: null,
        includeInfo: null,
        sizeWidth: 5015500.0,
        isHot: false,
        urlPicture:
          '2021/10/20/637703174146015116_macbook-pro-16-2021-xam-dd.jpg',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 13.000.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00785500',
          isRecurring: true,
          stockQuantity: 20,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 63990000.0,
          priceBeforeTax: 0.0,
          priceOnline: 0.0,
          priceMarket: 76990000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 651932,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: null,
        displayType: null,
        saleActiveDate: '2023-04-10T00:00:00',
        isUseLinkNew: null,
        storageAscii: null,
        nameGroup: null,
        nameAsciiGroup: null,
        nameParamAscii: null,
        id: 39365,
      },
      {
        labelID: 0,
        brandID: 15,
        brandName: 'LG',
        pictureID: 0,
        productTypeID: 3,
        name: 'LG Gram 16Z90Q-G.AH76A5 i7 1260P',
        nameAscii: 'lg-gram-16z90q-gah76a5-i7-1260p',
        code: null,
        includeInfo: null,
        sizeWidth: 4851000.0,
        isHot: false,
        urlPicture: '2022/9/16/637989186880560401_lg-gram-16-xam-dd.jpg',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 10.200.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00833353',
          isRecurring: true,
          stockQuantity: 10,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 36790000.0,
          priceBeforeTax: 0.0,
          priceOnline: 0.0,
          priceMarket: 46990000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 694667,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: null,
        displayType: null,
        saleActiveDate: '2023-04-10T00:00:00',
        isUseLinkNew: null,
        storageAscii: null,
        nameGroup: null,
        nameAsciiGroup: null,
        nameParamAscii: null,
        id: 42780,
      },
      {
        labelID: 0,
        brandID: 14,
        brandName: 'Lenovo',
        pictureID: 0,
        productTypeID: 3,
        name: 'Lenovo Gaming Legion 5 15IAH7H i7-12700H (82RB0047VN)',
        nameAscii: 'lenovo-gaming-legion-5-15iah7h-i7-12700h',
        code: null,
        includeInfo: null,
        sizeWidth: 0.0,
        isHot: false,
        urlPicture:
          '2023/3/23/638151643297943497_lenovo-gaming-legion-5-15iah7h-xam-dam-dd.jpg',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 10.000.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00857687',
          isRecurring: true,
          stockQuantity: 111,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 35990000.0,
          priceBeforeTax: 0.0,
          priceOnline: 0.0,
          priceMarket: 45990000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 714801,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: null,
        displayType: null,
        saleActiveDate: null,
        isUseLinkNew: null,
        storageAscii: null,
        nameGroup: null,
        nameAsciiGroup: null,
        nameParamAscii: null,
        id: 43637,
      },
      {
        labelID: 0,
        brandID: 10,
        brandName: 'HP',
        pictureID: 0,
        productTypeID: 3,
        name: 'HP Gaming OMEN 16-b0178TX i5 11400H (5Z9Q9PA)',
        nameAscii: 'hp-gaming-omen-16-b0178tx-i5-11400h',
        code: null,
        includeInfo: null,
        sizeWidth: 0.0,
        isHot: false,
        urlPicture:
          '2023/3/10/638140601029912158_hp-gaming-omen-16-rtx-3050ti-dd.jpg',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 10.000.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00815324',
          isRecurring: true,
          stockQuantity: 14,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 29990000.0,
          priceBeforeTax: 0.0,
          priceOnline: 0.0,
          priceMarket: 39990000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 677978,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: null,
        displayType: null,
        saleActiveDate: '2023-04-10T00:00:00',
        isUseLinkNew: null,
        storageAscii: null,
        nameGroup: null,
        nameAsciiGroup: null,
        nameParamAscii: null,
        id: 40913,
      },
      {
        labelID: 0,
        brandID: 10,
        brandName: 'HP',
        pictureID: 0,
        productTypeID: 3,
        name: 'HP Gaming VICTUS 16-d0291TX i7 11800H (5Z9R2PA)',
        nameAscii: 'hp-victus-16-d0291tx-i7-11800h',
        code: null,
        includeInfo: null,
        sizeWidth: 0.0,
        isHot: false,
        urlPicture:
          '2022/1/21/637783777003292588_hp-victus-16-den-dd-rtx-3050ti.jpg',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 8.500.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00799905',
          isRecurring: true,
          stockQuantity: 28,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 23990000.0,
          priceBeforeTax: 0.0,
          priceOnline: 0.0,
          priceMarket: 32490000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 663558,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: null,
        displayType: null,
        saleActiveDate: '2023-04-10T00:00:00',
        isUseLinkNew: null,
        storageAscii: null,
        nameGroup: null,
        nameAsciiGroup: null,
        nameParamAscii: null,
        id: 40317,
      },
      {
        labelID: 0,
        brandID: 223,
        brandName: 'Apple (MacBook)',
        pictureID: 0,
        productTypeID: 3,
        name: 'MacBook Air 13" 2020 M1 256GB',
        nameAscii: 'macbook-air-13-2020-m1-256gb',
        code: null,
        includeInfo: null,
        sizeWidth: 1636000.0,
        isHot: false,
        urlPicture: '2020/11/12/637407970062806725_mba-2020-gold-dd.png',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 8.200.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00725093',
          isRecurring: true,
          stockQuantity: 953,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 18799000.0,
          priceBeforeTax: 0.0,
          priceOnline: 28990000.0,
          priceMarket: 26999000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 601858,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: null,
        displayType: null,
        saleActiveDate: '2023-04-10T00:00:00',
        isUseLinkNew: null,
        storageAscii: '256gb',
        nameGroup: 'MacBook Air 13" 2020 M1',
        nameAsciiGroup: 'macbook-air-13-2020-m1',
        nameParamAscii: null,
        id: 35585,
      },
      {
        labelID: 0,
        brandID: 223,
        brandName: 'Apple (MacBook)',
        pictureID: 0,
        productTypeID: 3,
        name: 'MacBook Air 13" 2020 M1 16GB/256GB',
        nameAscii: 'macbook-air-13-2020-m1-16gb-256gb',
        code: null,
        includeInfo: null,
        sizeWidth: 2091000.0,
        isHot: false,
        urlPicture: '2020/11/12/637407970062806725_mba-2020-gold-dd.png',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 8.000.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00731891',
          isRecurring: true,
          stockQuantity: 173,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 25999000.0,
          priceBeforeTax: 0.0,
          priceOnline: 0.0,
          priceMarket: 33999000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 678864,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: 117,
        displayType: 3,
        saleActiveDate: '2023-04-10T00:00:00',
        isUseLinkNew: true,
        storageAscii: '256gb',
        nameGroup: 'MacBook Air 13" 2020 M1 Ram 16GB',
        nameAsciiGroup: 'macbook-air-13-2020-m1-ram-16gb',
        nameParamAscii: 'dung-luong',
        id: 36480,
      },
      {
        labelID: 0,
        brandID: 223,
        brandName: 'Apple (MacBook)',
        pictureID: 0,
        productTypeID: 3,
        name: 'MacBook Pro 13" 2020 Touch Bar M1 512GB',
        nameAscii: 'macbook-pro-13-2020-touch-bar-m1-512gb',
        code: null,
        includeInfo: null,
        sizeWidth: 2567500.0,
        isHot: false,
        urlPicture: '2020/11/13/637408530311831907_mbp-2020-m1-gray-dd-1.png',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 7.500.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00725102',
          isRecurring: true,
          stockQuantity: 85,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 30499000.0,
          priceBeforeTax: 0.0,
          priceOnline: 39990000.0,
          priceMarket: 37999000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 601867,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: null,
        displayType: null,
        saleActiveDate: '2023-04-10T00:00:00',
        isUseLinkNew: null,
        storageAscii: '512gb',
        nameGroup: 'MacBook Pro 13" 2020 Touch Bar M1',
        nameAsciiGroup: 'macbook-pro-13-2020-touch-bar-m1',
        nameParamAscii: null,
        id: 35588,
      },
      {
        labelID: 0,
        brandID: 3,
        brandName: 'Asus',
        pictureID: 0,
        productTypeID: 3,
        name: 'Asus Gaming TUF FA507RM-HN018W R7 6800H',
        nameAscii: 'asus-gaming-tuf-fa507rm-hn018w-r7-6800h',
        code: null,
        includeInfo: null,
        sizeWidth: 3409000.0,
        isHot: false,
        urlPicture:
          '2022/9/20/637992834466231416_asus-tuf-gaming-fa507-xam-dd.jpg',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 7.000.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00833413',
          isRecurring: true,
          stockQuantity: 10,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 25990000.0,
          priceBeforeTax: 0.0,
          priceOnline: 0.0,
          priceMarket: 32990000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 694722,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: null,
        displayType: null,
        saleActiveDate: '2023-04-10T00:00:00',
        isUseLinkNew: null,
        storageAscii: null,
        nameGroup: null,
        nameAsciiGroup: null,
        nameParamAscii: null,
        id: 42793,
      },
      {
        labelID: 0,
        brandID: 3,
        brandName: 'Asus',
        pictureID: 0,
        productTypeID: 3,
        name: 'Asus TUF Gaming FX507ZE-HN093W i7 12700H',
        nameAscii: 'asus-tuf-gaming-fx507ze-hn093w-i7-12700h',
        code: null,
        includeInfo: null,
        sizeWidth: 3460500.0,
        isHot: false,
        urlPicture:
          '2022/7/23/637941852801053447_asus-tuf-gaming-fx507-xam-dd=rtx-3050ti.jpg',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 7.000.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00824251',
          isRecurring: true,
          stockQuantity: 32,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 26490000.0,
          priceBeforeTax: 0.0,
          priceOnline: 0.0,
          priceMarket: 33490000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 685494,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: null,
        displayType: null,
        saleActiveDate: '2023-04-10T00:00:00',
        isUseLinkNew: null,
        storageAscii: null,
        nameGroup: null,
        nameAsciiGroup: null,
        nameParamAscii: null,
        id: 42362,
      },
      {
        labelID: 0,
        brandID: 10,
        brandName: 'HP',
        pictureID: 0,
        productTypeID: 3,
        name: 'HP ZBook Firefly 14 G8 i5 1135G7 (1A2F1AV)',
        nameAscii: 'hp-zbook-firefly-14-g8-i5-1135g7-ram-8gb',
        code: null,
        includeInfo: null,
        sizeWidth: 3512000.0,
        isHot: false,
        urlPicture:
          '2021/9/4/637663501702829712_hp-zbook-firefly-14-g8-xam-dd.jpg',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 7.000.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00791281',
          isRecurring: true,
          stockQuantity: 10,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 26990000.0,
          priceBeforeTax: 0.0,
          priceOnline: 0.0,
          priceMarket: 33990000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 656233,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: null,
        displayType: null,
        saleActiveDate: '2023-04-10T00:00:00',
        isUseLinkNew: true,
        storageAscii: '8gb',
        nameGroup: 'Laptop HP ZBook Firefly 14 G8 i5 1135G7',
        nameAsciiGroup: 'hp-zbook-firefly-14-g8-i5-1135g7',
        nameParamAscii: 'dung-luong-ram',
        id: 39676,
      },
      {
        labelID: 0,
        brandID: 14,
        brandName: 'Lenovo',
        pictureID: 0,
        productTypeID: 3,
        name: 'Lenovo ThinkPad T14s Gen 2 i5-1135G7 (20WM01T1VN)',
        nameAscii: 'lenovo-thinkpad-t14s-gen-2-i5-1135g7',
        code: null,
        includeInfo: null,
        sizeWidth: 3306000.0,
        isHot: false,
        urlPicture:
          '2023/2/14/638119836706226424_lenovo-thinkpad-t14s-gen-2-den-dd.jpg',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 6.500.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00858239',
          isRecurring: true,
          stockQuantity: 60,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 25990000.0,
          priceBeforeTax: 0.0,
          priceOnline: 0.0,
          priceMarket: 32490000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 715448,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: null,
        displayType: null,
        saleActiveDate: null,
        isUseLinkNew: null,
        storageAscii: null,
        nameGroup: null,
        nameAsciiGroup: null,
        nameParamAscii: null,
        id: 43706,
      },
      {
        labelID: 0,
        brandID: 3,
        brandName: 'Asus',
        pictureID: 0,
        productTypeID: 3,
        name: 'Asus TUF Gaming FX517ZE-HN045W i5 12450H',
        nameAscii: 'asus-tuf-gaming-fx517ze-hn045w-i5-12450h',
        code: null,
        includeInfo: null,
        sizeWidth: 0.0,
        isHot: false,
        urlPicture:
          '2023/3/10/638140638161724111_asus-tuf-gaming-fx517-den-dd-rtx-3050-dd.jpg',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 6.500.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00823171',
          isRecurring: true,
          stockQuantity: 151,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 23490000.0,
          priceBeforeTax: 0.0,
          priceOnline: 0.0,
          priceMarket: 29990000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 684436,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: null,
        displayType: null,
        saleActiveDate: '2023-04-10T00:00:00',
        isUseLinkNew: null,
        storageAscii: null,
        nameGroup: null,
        nameAsciiGroup: null,
        nameParamAscii: null,
        id: 42029,
      },
      {
        labelID: 0,
        brandID: 3,
        brandName: 'Asus',
        pictureID: 0,
        productTypeID: 3,
        name: 'Asus TUF Gaming FX517ZC-HN077W i5 12450H',
        nameAscii: 'asus-tuf-gaming-fx517zc-hn077w-i5-12450h',
        code: null,
        includeInfo: null,
        sizeWidth: 2997000.0,
        isHot: false,
        urlPicture:
          '2023/3/10/638140638161724111_asus-tuf-gaming-fx517-den-dd-rtx-3050-dd.jpg',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 6.500.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00800822',
          isRecurring: true,
          stockQuantity: 165,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 22490000.0,
          priceBeforeTax: 0.0,
          priceOnline: 0.0,
          priceMarket: 28990000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 664459,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: null,
        displayType: null,
        saleActiveDate: '2023-04-10T00:00:00',
        isUseLinkNew: null,
        storageAscii: null,
        nameGroup: null,
        nameAsciiGroup: null,
        nameParamAscii: null,
        id: 40497,
      },
      {
        labelID: 0,
        brandID: 3,
        brandName: 'Asus',
        pictureID: 0,
        productTypeID: 3,
        name: 'Asus TUF Gaming FA507RC-HN051W R7 6800H',
        nameAscii: 'asus-tuf-gaming-fa507rc-hn051w-r7-6800h',
        code: null,
        includeInfo: null,
        sizeWidth: 2945500.0,
        isHot: false,
        urlPicture:
          '2023/3/10/638140640007038690_asus-tuf-gaming-fa507-xam-dd-rtx3050.jpg',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 6.400.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00814963',
          isRecurring: true,
          stockQuantity: 11,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 22090000.0,
          priceBeforeTax: 0.0,
          priceOnline: 0.0,
          priceMarket: 28490000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 677705,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: null,
        displayType: null,
        saleActiveDate: '2023-04-10T00:00:00',
        isUseLinkNew: null,
        storageAscii: null,
        nameGroup: null,
        nameAsciiGroup: null,
        nameParamAscii: null,
        id: 40909,
      },
      {
        labelID: 0,
        brandID: 15,
        brandName: 'LG',
        pictureID: 0,
        productTypeID: 3,
        name: 'LG Gram 14Z90Q-G.AJ32A5 i3 1220P',
        nameAscii: 'lg-gram-14z90q-gaj32a5-i3-1220p',
        code: null,
        includeInfo: null,
        sizeWidth: 2688000.0,
        isHot: false,
        urlPicture: '2022/9/14/637987492065481643_lg-gram-14-den-dd.jpg',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 6.200.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00832764',
          isRecurring: true,
          stockQuantity: 8,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 19790000.0,
          priceBeforeTax: 0.0,
          priceOnline: 0.0,
          priceMarket: 25990000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 693856,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: null,
        displayType: null,
        saleActiveDate: '2023-04-10T00:00:00',
        isUseLinkNew: null,
        storageAscii: null,
        nameGroup: null,
        nameAsciiGroup: null,
        nameParamAscii: null,
        id: 42706,
      },
      {
        labelID: 0,
        brandID: 3,
        brandName: 'Asus',
        pictureID: 0,
        productTypeID: 3,
        name: 'Asus Gaming  Zephyrus GA401QC-K2199W R7-5800HS',
        nameAscii: 'asus-zephyrus-gaming-ga401qc-k2199w-r7-5800hs',
        code: null,
        includeInfo: null,
        sizeWidth: 3409000.0,
        isHot: false,
        urlPicture:
          '2021/10/5/637690223282928327_asus-gaming-zephyrus-ga401-xam-noled-dd.jpg',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 6.000.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00833414',
          isRecurring: true,
          stockQuantity: 12,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 26990000.0,
          priceBeforeTax: 0.0,
          priceOnline: 0.0,
          priceMarket: 32990000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 694724,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: null,
        displayType: null,
        saleActiveDate: '2023-04-10T00:00:00',
        isUseLinkNew: null,
        storageAscii: null,
        nameGroup: null,
        nameAsciiGroup: null,
        nameParamAscii: null,
        id: 42794,
      },
      {
        labelID: 0,
        brandID: 10,
        brandName: 'HP',
        pictureID: 0,
        productTypeID: 3,
        name: 'HP Pavilion 15-eg2034TX i7 1255U/6K780PA',
        nameAscii: 'hp-pavilion-15-eg2034tx-i7-1255u',
        code: null,
        includeInfo: null,
        sizeWidth: 3039000.0,
        isHot: false,
        urlPicture:
          '2022/7/29/637947159455512547_hp-pavilion-15-eg2059tu-i5-1240p-vang-dd.jpg',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 6.000.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00825046',
          isRecurring: true,
          stockQuantity: 66,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 18590000.0,
          priceBeforeTax: 0.0,
          priceOnline: 0.0,
          priceMarket: 24590000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 686378,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: null,
        displayType: null,
        saleActiveDate: '2023-04-10T00:00:00',
        isUseLinkNew: null,
        storageAscii: null,
        nameGroup: null,
        nameAsciiGroup: null,
        nameParamAscii: null,
        id: 42428,
      },
      {
        labelID: 0,
        brandID: 223,
        brandName: 'Apple (MacBook)',
        pictureID: 0,
        productTypeID: 3,
        name: 'MacBook Pro M2 13 2022 8CPU 10GPU 16GB 256GB',
        nameAscii: 'macbook-pro-m2-2022-13-inch-ram-16gb-256gb',
        code: null,
        includeInfo: null,
        sizeWidth: 2740500.0,
        isHot: false,
        urlPicture:
          '2022/6/7/637901935364438149_macbook-pro-m2-2022-xam-dd.jpg',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 6.000.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00819893',
          isRecurring: true,
          stockQuantity: 175,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 35990000.0,
          priceBeforeTax: 0.0,
          priceOnline: 0.0,
          priceMarket: 41990000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 681546,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: 254,
        displayType: 3,
        saleActiveDate: '2023-04-10T00:00:00',
        isUseLinkNew: true,
        storageAscii: '256gb',
        nameGroup: 'MacBook Pro M2 2022 13 inch Ram 16GB',
        nameAsciiGroup: 'macbook-pro-m2-2022-13-inch-ram-16gb',
        nameParamAscii: 'dung-luong',
        id: 41113,
      },
      {
        labelID: 0,
        brandID: 223,
        brandName: 'Apple (MacBook)',
        pictureID: 0,
        productTypeID: 3,
        name: 'MacBook Air M2 13 2022 8CPU 8GPU 8GB 256GB',
        nameAscii: 'macbook-air-m2-2022-13-inch-256gb',
        code: null,
        includeInfo: null,
        sizeWidth: 2155500.0,
        isHot: false,
        urlPicture:
          '2022/6/7/637901915720184032_macbook-air-m2-2022-den-dd.jpg',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 6.000.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00818144',
          isRecurring: true,
          stockQuantity: 269,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 26990000.0,
          priceBeforeTax: 0.0,
          priceOnline: 0.0,
          priceMarket: 32990000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 680077,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: 252,
        displayType: 3,
        saleActiveDate: '2023-04-10T00:00:00',
        isUseLinkNew: true,
        storageAscii: '256gb',
        nameGroup: 'MacBook Air M2 2022 13 inch',
        nameAsciiGroup: 'macbook-air-m2-2022-13-inch',
        nameParamAscii: 'dung-luong',
        id: 41001,
      },
      {
        labelID: 0,
        brandID: 6,
        brandName: 'Dell',
        pictureID: 0,
        productTypeID: 3,
        name: 'Dell Latitude L5420CTO i5 135G7 (42LT542005)',
        nameAscii: 'dell-latitude-5420cto-i5-1135g7-win-11',
        code: null,
        includeInfo: null,
        sizeWidth: 2688000.0,
        isHot: false,
        urlPicture:
          '2021/12/21/637756804982281798_dell-latitude-l5420cto-xam-dd.jpg',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 6.000.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00791171',
          isRecurring: true,
          stockQuantity: 16,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 19990000.0,
          priceBeforeTax: 0.0,
          priceOnline: 0.0,
          priceMarket: 25990000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 656160,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: null,
        displayType: null,
        saleActiveDate: null,
        isUseLinkNew: true,
        storageAscii: 'win-11',
        nameGroup: 'Laptop Dell Latitude L5420CTO i5 135G7',
        nameAsciiGroup: 'dell-latitude-5420cto-i5-1135g7',
        nameParamAscii: 'he-dieu-hanh',
        id: 39666,
      },
      {
        labelID: 0,
        brandID: 14,
        brandName: 'Lenovo',
        pictureID: 0,
        productTypeID: 3,
        name: 'Lenovo IdeaPad Slim 5 R7 4700U (NK)',
        nameAscii: 'ideapad-slim-5-r7-4700u',
        code: null,
        includeInfo: null,
        sizeWidth: 2472500.0,
        isHot: false,
        urlPicture:
          '2022/7/7/637927983005069400_ideapad-slim-5-r7-4700u-xam-dd.jpg',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 6.000.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00785469',
          isRecurring: true,
          stockQuantity: 40,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 13990000.0,
          priceBeforeTax: 0.0,
          priceOnline: 0.0,
          priceMarket: 19990000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 651901,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: null,
        displayType: null,
        saleActiveDate: null,
        isUseLinkNew: null,
        storageAscii: null,
        nameGroup: null,
        nameAsciiGroup: null,
        nameParamAscii: null,
        id: 39359,
      },
      {
        labelID: 0,
        brandID: 14,
        brandName: 'Lenovo',
        pictureID: 0,
        productTypeID: 3,
        name: 'Lenovo Ideapad 3 i3 1005G1 (NK)',
        nameAscii: 'lenovo-ideapad-3-i3-1005g1-nk',
        code: null,
        includeInfo: null,
        sizeWidth: 1697500.0,
        isHot: false,
        urlPicture:
          '2022/5/16/637883111212313580_lenovo-ideapad-3-i3-1005g1-nk-xam-ddjpg.jpg',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 6.000.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00782950',
          isRecurring: true,
          stockQuantity: 25,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 7690000.0,
          priceBeforeTax: 0.0,
          priceOnline: 0.0,
          priceMarket: 13690000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 650062,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: null,
        displayType: null,
        saleActiveDate: null,
        isUseLinkNew: null,
        storageAscii: null,
        nameGroup: null,
        nameAsciiGroup: null,
        nameParamAscii: null,
        id: 39197,
      },
      {
        labelID: 0,
        brandID: 223,
        brandName: 'Apple (MacBook)',
        pictureID: 0,
        productTypeID: 3,
        name: 'MacBook Pro M2 2022 13 inch 8CPU 10GPU 8GB 256GB',
        nameAscii: 'macbook-pro-m2-2022-13-inch-256gb',
        code: null,
        includeInfo: null,
        sizeWidth: 2350500.0,
        isHot: false,
        urlPicture:
          '2022/6/7/637901935364438149_macbook-pro-m2-2022-xam-dd.jpg',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 5.700.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00818154',
          isRecurring: true,
          stockQuantity: 19,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 30290000.0,
          priceBeforeTax: 0.0,
          priceOnline: 0.0,
          priceMarket: 35990000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 680085,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: null,
        displayType: null,
        saleActiveDate: '2023-04-10T00:00:00',
        isUseLinkNew: true,
        storageAscii: '256gb',
        nameGroup: 'MacBook Pro M2 2022 13 inch',
        nameAsciiGroup: 'macbook-pro-m2-2022-13-inch',
        nameParamAscii: 'dung-luong',
        id: 41003,
      },
      {
        labelID: 0,
        brandID: 10,
        brandName: 'HP',
        pictureID: 0,
        productTypeID: 3,
        name: 'Laptop HP Gaming Victus 16-e1107AX R5-6600H',
        nameAscii: 'hp-gaming-victus-16-e1107ax-r5-6600h',
        code: null,
        includeInfo: null,
        sizeWidth: 0.0,
        isHot: false,
        urlPicture:
          '2023/2/17/638122294500233505_hp-gaming-victus-16-e1107ax-r5-6600h-den-dd.jpg',
        displayDiscount: '0',
        urlLabelPicture: null,
        labelName: null,
        labelInst: 'Trả góp 0%',
        labelFlashSale: 'Giảm 5.500.000đ',
        urlLabelPictureMobile: null,
        hightlightsShortDes: null,
        productVariant: {
          productID: 0,
          colorID: 0,
          sku: '00859672',
          isRecurring: true,
          stockQuantity: 40,
          disableBuyButton: false,
          availableForPreOrder: false,
          callForPrice: false,
          price: 19990000.0,
          priceBeforeTax: 0.0,
          priceOnline: 0.0,
          priceMarket: 25490000.0,
          isAllowOrderOutStock: false,
          isApplyDiscount: null,
          colorName: null,
          isApplyDiscountSpecial: null,
          isApplyDiscountCrossell: null,
          isApplyDiscountShoppingCart: null,
          amountDiscountOnline: 0.0,
          color: null,
          gallery: null,
          listGallery: null,
          promotionText: null,
          promotionAdmin: null,
          promotionSummary: null,
          minDeposit: null,
          showBuyButton: false,
          currentPrice: null,
          urlPicture: null,
          priceAfterPromotion: null,
          priceOnlineAfterPromotion: null,
          typePrice: null,
          colorImageUrl: null,
          labelInst: null,
          priceProSaleMin: null,
          isComingSoon: 0,
          totalStock: 0,
          id: 716888,
        },
        productType: {
          name: 'Máy tính xách tay',
          nameAscii: 'may-tinh-xach-tay',
          id: 3,
        },
        hightlightsDes: null,
        urlHotPicture: null,
        promotionInfo: null,
        isComingSoon: false,
        displayOrderBestSellerType: null,
        totalRating: 0,
        advRating: 0.0,
        isNotBusiness: false,
        totalOrder: null,
        totaView: null,
        urlPictureHotCateLaptop: null,
        promotionName: null,
        promotionUrlImage: null,
        priceShockDiscount: null,
        isAutoUpdateLabel: true,
        labelLeftID: null,
        labelLeftName: 'Trả góp 0%',
        urlLabelLeftPicture: null,
        productGroupId: null,
        displayType: null,
        saleActiveDate: '2023-04-10T00:00:00',
        isUseLinkNew: null,
        storageAscii: null,
        nameGroup: null,
        nameAsciiGroup: null,
        nameParamAscii: null,
        id: 43747,
      },
    ],
  },
  sort: 'SortDealsOnlineDESC',
  numberRecord: 27,
  productFlashSale: {
    name: null,
    description: null,
    startDate: null,
    endDate: null,
    total: 0,
    numberRecord: 20,
    productItems: [],
    attributeSpecItems: [],
    promotionItems: [],
  },
  productHots: null,
  navFilterAttributeItem: {
    attributeItems: [
      {
        name: 'Mức giá',
        nameAscii: 'muc-gia',
        isShowNavFilter: true,
        levelOrder: null,
        id: 169,
      },
      {
        name: 'Màn hình',
        nameAscii: 'man-hinh',
        isShowNavFilter: true,
        levelOrder: null,
        id: 202,
      },
      {
        name: 'CPU',
        nameAscii: 'cpu',
        isShowNavFilter: true,
        levelOrder: 5,
        id: 198,
      },
      {
        name: 'RAM',
        nameAscii: 'ram',
        isShowNavFilter: true,
        levelOrder: 4,
        id: 199,
      },
      {
        name: 'Card đồ họa',
        nameAscii: 'card-do-hoa',
        isShowNavFilter: true,
        levelOrder: null,
        id: 200,
      },
      {
        name: 'Ổ cứng',
        nameAscii: 'o-cung',
        isShowNavFilter: true,
        levelOrder: null,
        id: 203,
      },
      {
        name: 'Tính năng đặc biệt',
        nameAscii: 'tinh-nang-dac-biet',
        isShowNavFilter: true,
        levelOrder: null,
        id: 205,
      },
      {
        name: 'Nhu cầu',
        nameAscii: 'nhu-cau',
        isShowNavFilter: true,
        levelOrder: null,
        id: 133,
      },
      {
        name: 'model series',
        nameAscii: 'model-series',
        isShowNavFilter: false,
        levelOrder: null,
        id: 283,
      },
    ],
    specItems: [
      {
        attributeID: 198,
        attributeCustomID: 677,
        name: 'Intel Celeron',
        nameAscii: 'intel-celeron',
        displayOrder: 12,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'CPU',
        attrCusGroupNameAscii: 'cpu',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop CPU Intel Celeron | Chính hãng, trả góp 0%',
        seoDescription:
          'Mua laptop, máy tính xách tay trang bị CPU Intel Celeron cấu hình ổn định, thực hiện tốt các tác vụ. Ưu đãi hấp dẫn, giao nhanh toàn quốc, bảo hành chính hãng',
        seoKeyword: 'laptop celeron',
        urlThumb: null,
        attrCusGroupLevelOrder: 5,
        id: 0,
      },
      {
        attributeID: 198,
        attributeCustomID: 678,
        name: 'Intel Pentium',
        nameAscii: 'intel-pentium',
        displayOrder: 11,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'CPU',
        attrCusGroupNameAscii: 'cpu',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop CPU Intel Pentium | Giá rẻ, trả góp 0%',
        seoDescription:
          'Mua laptop, máy tính xách tay trang bị CPU Intel Pentium hiệu năng ổn định với mức giá phải chăng. Hỗ trợ trả góp 0%, miễn phí giao hàng, bảo hành chính hãng',
        seoKeyword: 'laptop pentium',
        urlThumb: null,
        attrCusGroupLevelOrder: 5,
        id: 0,
      },
      {
        attributeID: 205,
        attributeCustomID: 698,
        name: 'Hỗ trợ công nghệ Optane',
        nameAscii: 'ho-tro-cong-nghe-optane',
        displayOrder: 10,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Tính năng đặc biệt',
        attrCusGroupNameAscii: 'tinh-nang-dac-biet',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop sử dụng bộ nhớ Intel Optane | Chính hãng',
        seoDescription:
          'Mua máy tính xách tay, laptop sử dụng bộ nhớ Intel Optane cho khả năng phản hồi, xử lý nhanh chóng. Trả góp 0%, miễn phí giao hàng, bảo hành chính hãng',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 198,
        attributeCustomID: 674,
        name: 'Intel Core i3',
        nameAscii: 'intel-core-i3',
        displayOrder: 10,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'CPU',
        attrCusGroupNameAscii: 'cpu',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop Core i3 giá rẻ | Chính hãng, mẫu mới 2022',
        seoDescription:
          'Mua laptop Core i3 dòng intel thế hệ mới nhất, giá rẻ dưới 15 triệu đồng, nhiều mẫu mã, cấu hình ổn định. Miễn phí giao nhanh trong 1H, bảo hành chính hãng',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: 5,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 1905,
        name: 'MSI Gaming',
        nameAscii: 'msi-gaming',
        displayOrder: 10,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop MSI Gaming | Cấu hình mạnh, trả góp 0%',
        seoDescription:
          'Mua laptop MSI Gaming thiết kế đậm chất gaming, cấu hình mạnh mẽ cho hiệu năng chiến game vượt trội. Trả góp 0%, bảo hành chính hãng, miễn phí giao hàng',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 169,
        attributeCustomID: 567,
        name: 'Dưới 10 triệu',
        nameAscii: 'duoi-10-trieu',
        displayOrder: 10,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Mức giá',
        attrCusGroupNameAscii: 'muc-gia',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop giá rẻ dưới 10 triệu | Mẫu mới 2023, trả góp 0%',
        seoDescription:
          'Mua máy tính xách tay, laptop giá rẻ dưới 10 triệu tốt nhất, phù hợp học sinh - sinh viên, nhân viên văn phòng. Giá giảm lớn, bảo hành chính hãng, trả góp 0%',
        seoKeyword: 'laptop dưới 10 triệu, laptop giá rẻ',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 943,
        name: 'MacBook Pro 16',
        nameAscii: 'macbook-pro-16',
        displayOrder: 10,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle:
          'MacBook Pro 16 inch 2021 - 2023 chính hãng | Trợ giá lên đời',
        seoDescription:
          'Mua MacBook Pro 16 inch 2021 - 2023 với đầy đủ phiên bản dung lượng, chip M2 Max & M2 Pro, hỗ trợ trả góp 0%, thu cũ đổi mới, trợ giá lên đời đến 15%.',
        seoKeyword:
          'macbook pro 16 inch, macbook pro 16, macbook pro 16 inch 2021, macbook pro 16 inch 2023',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 944,
        name: 'Lenovo IdeaPad',
        nameAscii: 'lenovo-ideapad',
        displayOrder: 10,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Lenovo IdeaPad | Laptop phục vụ văn phòng, học tập',
        seoDescription:
          'Mua laptop dòng Lenovo IdeaPad thiết kế mỏng nhẹ, nhỏ gọn, phục vụ tốt mọi tác vụ văn phòng, học tập. Cam kết chính hãng, trả góp 0%, bảo hành 24 tháng',
        seoKeyword: 'Lenovo IdeaPad',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 199,
        attributeCustomID: 683,
        name: '4 GB',
        nameAscii: '4-gb',
        displayOrder: 10,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'RAM',
        attrCusGroupNameAscii: 'ram',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop RAM 4GB | Chính hãng 100%, giá tốt nhất',
        seoDescription:
          'Mua laptop, máy tính xách tay trang bị RAM 4GB giá rẻ, ưu đãi tốt nhất thị trường. Trả góp 0%, miễn phí giao hàng, bảo hành chính hãng, đổi trả linh hoạt',
        seoKeyword: 'laptop ram 4gb',
        urlThumb: null,
        attrCusGroupLevelOrder: 4,
        id: 0,
      },
      {
        attributeID: 202,
        attributeCustomID: 690,
        name: 'Khoảng 13 inch',
        nameAscii: 'khoang-13-inch',
        displayOrder: 10,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Màn hình',
        attrCusGroupNameAscii: 'man-hinh',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop 13 inch | Mẫu mới nhất, giá ưu đãi, trả góp 0%',
        seoDescription:
          'Mua laptop 13 inch, 13.3 inch giá rẻ, mẫu mới nhất 2023 với thiết kế gọn nhẹ, hiện đại. Giá ưu đãi nhất, trả góp 0%, bảo hành chính hãng, giao nhanh trong 1H',
        seoKeyword: 'laptop 13 inch, laptop 13.3 inch',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 133,
        attributeCustomID: 1569,
        name: 'Gaming',
        nameAscii: 'gaming-do-hoa',
        displayOrder: 10,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Nhu cầu',
        attrCusGroupNameAscii: 'nhu-cau',
        urlPicture: '2022/9/19/637991744177742277_img-gaming.png',
        attrCusIsTop: true,
        seoTitle: 'Laptop gaming | Cấu hình khủng, giá rẻ nhất 2023',
        seoDescription:
          'Mua laptop gaming, máy tính chơi game giá rẻ, cấu hình khủng, tản nhiệt tốt nhất tại FPT Shop. Mẫu mới nhất 2023, trả góp 0%, bảo hành chính hãng toàn quốc',
        seoKeyword: 'laptop gaming, laptop chơi game',
        urlThumb: '2021/8/2/637634960298145823_Gaming.png',
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 957,
        name: 'Acer Aspire',
        nameAscii: 'acer-aspire',
        displayOrder: 10,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Acer Aspire | Laptop phổ thông giá rẻ, trả góp 0%',
        seoDescription:
          'Mua laptop Acer Aspire giá rẻ có thiết kế đơn giản mỏng nhẹ, thời lượng sử dụng pin lâu. Phục vụ tốt công việc văn phòng, học tập, giải trí nhẹ nhàng',
        seoKeyword: 'Acer Aspire',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 929,
        name: 'Asus VivoBook',
        nameAscii: 'asus-vivobook',
        displayOrder: 10,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop Asus VivoBook｜Thiết kế trẻ trung, mỏng nhẹ',
        seoDescription:
          'Mua laptop Asus VivoBook mẫu mã trẻ trung, năng động với thiết kế gọn nhẹ, phong cách hiện đại cùng cấu hình mạnh mẽ. Cam kết chính hãng, hỗ trợ trả góp 0%',
        seoKeyword: 'asus vivobook, laptop asus vivobook',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 933,
        name: 'Dell Inspiron',
        nameAscii: 'dell-inspiron',
        displayOrder: 10,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop Dell Inspiron | Giá rẻ, mẫu mới nhất 2022',
        seoDescription:
          'Mua laptop Dell Inspiron giá rẻ với độ bền vượt trội, phục vụ tốt nhu cầu học tập, làm việc, giải trí. Trả góp 0%, miễn phí giao hàng, bảo hành chính hãng',
        seoKeyword: 'dell inspiron, laptop dell inspiron',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 937,
        name: 'HP 14/15 - 14s/15s',
        nameAscii: 'hp-14s-15s',
        displayOrder: 10,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop HP 14/15 - 14s/15s | Giá rẻ, thiết kế gọn nhẹ',
        seoDescription:
          'Mua laptop HP 14s/15s , 14/15 phù hợp sinh viên, nhân viên văn phòng. Bảo hành chính hãng, hỗ trợ trả góp 0%, quà tặng giá trị, đặt online giao nhanh trong 1H',
        seoKeyword: 'hp 15s, hp 14s, hp 15, hp 14',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 938,
        name: 'HP cơ bản',
        nameAscii: 'hp-co-ban',
        displayOrder: 9,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop HP cơ bản chính hãng | Trả góp 0%',
        seoDescription:
          'Dòng laptop HP cơ bản dòng HP 240 với thiết kế gọn nhẹ giúp bạn dễ dàng di chuyển. Hỗ trợ trả góp 0%, quà tặng hấp dẫn, miễn phí giao hàng, giá tốt nhất',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 934,
        name: 'Dell Vostro',
        nameAscii: 'dell-vostro',
        displayOrder: 9,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Dell Vostro | Máy tính chính hãng, trả góp 0%',
        seoDescription:
          'Mua laptop Dell Vostro phù hợp với nhu cầu học tập, văn phòng với thiết kế hiện đại cùng hiệu năng tuyệt vời. Trả góp 0% duyệt nhanh, bảo hành chính hãng',
        seoKeyword: 'Dell Vostro',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 930,
        name: 'Asus ZenBook',
        nameAscii: 'asus-zenbook',
        displayOrder: 9,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Asus ZenBook | Laptop 13 -14 inch nhỏ gọn, giá tốt nhất',
        seoDescription:
          'Mua laptop Asus ZenBook thiết kế mỏng nhẹ, nhỏ gọn, cấu hình cao. Giá ưu đãi tốt nhất với nhiều quà tặng hấp dẫn, trả góp 0%, miễn phí giao nhanh trong 1H',
        seoKeyword: 'Asus ZenBook',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 958,
        name: 'Acer Swift',
        nameAscii: 'acer-swift',
        displayOrder: 9,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Acer Swift | Laptop sang trọng, cực mỏng nhẹ',
        seoDescription:
          'Mua laptop Acer Swift với thiết kế thời trang, siêu mỏng nhẹ, dễ dàng di chuyển, phục vụ tốt mọi tác vụ. Hỗ trợ trả góp 0%, trả trước 0đ, giao nhanh trong 1h',
        seoKeyword: 'Acer Swift',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 202,
        attributeCustomID: 691,
        name: 'Khoảng 14 inch',
        nameAscii: 'khoang-14-inch',
        displayOrder: 9,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Màn hình',
        attrCusGroupNameAscii: 'man-hinh',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop 14 inch giá rẻ | Mẫu mới nhất, thiết kế đẹp',
        seoDescription:
          'Mua laptop màn hình 14 inch giá rẻ, thiết kế mỏng nhẹ, cấu hình ổn định. Miễn phí giao nhanh trong 1H, bảo hành chính hãng, hỗ trợ trả góp 0% xét duyệt nhanh',
        seoKeyword: 'laptop 14 inch',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 945,
        name: 'Lenovo ThinkPad',
        nameAscii: 'lenovo-thinkpad',
        displayOrder: 9,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Lenovo ThinkPad | Laptop cao cấp, siêu bền bỉ',
        seoDescription:
          'Mua laptop Lenovo ThinkPad có hiệu năng mạnh mẽ, thời lượng pin sử dụng đến 17h. Cam kết chính hãng, trả góp 0%. Mua online miễn phí giao nhanh trong 1 giờ',
        seoKeyword: 'lenovo thinkpad, laptop thinkpad',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 1904,
        name: 'MSI Bravo',
        nameAscii: 'msi-bravo',
        displayOrder: 9,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'MSI Bravo | Laptop gaming chính hãng, trả góp 0%',
        seoDescription:
          'Mua máy tính chơi game MSI Bravo trang bị bàn phím gaming chuyên biệt, âm thanh chân thực cùng cấu hình mạnh mẽ. Trả góp 0%, bảo hành chính hãng 12 tháng',
        seoKeyword: 'MSI Bravo',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 169,
        attributeCustomID: 568,
        name: 'Từ 10 - 15 triệu',
        nameAscii: 'tu-10-15-trieu',
        displayOrder: 9,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Mức giá',
        attrCusGroupNameAscii: 'muc-gia',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop giá rẻ 10 đến 15 triệu | Trả góp 0%, mẫu mới 2023',
        seoDescription:
          'Mua laptop giá rẻ dưới 15 triệu mỏng nhẹ, thiết kế hiện đại, cho nhu cầu học tập, văn phòng, cấu hình ổn. Giá giảm cực sốc, bảo hành chính hãng, trả góp 0%',
        seoKeyword: 'laptop 10 triệu, laptop dưới 15 triệu, laptop 15 triệu',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 198,
        attributeCustomID: 675,
        name: 'Intel Core i5',
        nameAscii: 'intel-core-i5',
        displayOrder: 9,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'CPU',
        attrCusGroupNameAscii: 'cpu',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop Core i5 | Chính hãng, giá rẻ nhất thị trường',
        seoDescription:
          'Mua laptop trang bị CPU Core i5 nhiều mẫu mã lựa chọn, hiệu năng đột phát, giá phù hợp nhiều đối tượng.  Miễn phí giao hàng tận nơi, bảo hành chính hãng',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: 5,
        id: 0,
      },
      {
        attributeID: 133,
        attributeCustomID: 1557,
        name: 'Sinh viên - Văn phòng',
        nameAscii: 'sinh-vien-van-phong',
        displayOrder: 9,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Nhu cầu',
        attrCusGroupNameAscii: 'nhu-cau',
        urlPicture: '2022/9/6/637980583313032986_SV-Văn phòng.png',
        attrCusIsTop: true,
        seoTitle: 'Laptop sinh viên - văn phòng | Giá rẻ, trả góp 0%',
        seoDescription:
          'Mua laptop sinh viên, văn phòng cấu hình ổn định, đa nhiệm tốt, phục vụ tốt nhu cầu học tập, làm việc. Mẫu cập nhật mới nhất, trả góp 0%, miễn phí giao hàng',
        seoKeyword:
          'laptop giá rẻ cho sinh viên, laptop sinh viên, laptop văn phòng',
        urlThumb: '2022/9/6/637980583313032986_SV-Văn phòng.png',
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 205,
        attributeCustomID: 2229,
        name: 'CPU Intel',
        nameAscii: 'cpu-intel',
        displayOrder: 9,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Tính năng đặc biệt',
        attrCusGroupNameAscii: 'tinh-nang-dac-biet',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop Intel | Chính hãng, giá tốt nhất',
        seoDescription:
          'Mua laptop trang bị CPU Intel với nhiều mẫu mã cho mọi nhu cầu. Giá tốt nhất, nhiều ưu đãi, bảo hành chính hãng, hậu mãi chu đáo. Mua ngay tại FPTShop!',
        seoKeyword: 'laptop intel',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 203,
        attributeCustomID: 694,
        name: 'SSD 1 TB',
        nameAscii: 'ssd-1-tb',
        displayOrder: 9,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Ổ cứng',
        attrCusGroupNameAscii: 'o-cung',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop ổ cứng SSD 1TB | Siêu nhanh, siêu mượt',
        seoDescription:
          'Mua laptop trang bị ổ cứng SSD 1TB cho tốc độ truyền tải nhanh, hoạt động siêu mượt mà, hỗ trợ trả góp 0%, bảo hành chính hãng 1+ năm. Mua ngay!',
        seoKeyword: 'laptop ổ cứng ssd 1tb, laptop ssd 1tb',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 1529,
        name: 'MacBook Pro 14',
        nameAscii: 'macbook-pro-14',
        displayOrder: 9,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'MacBook Pro 14 inch 2021 - 2023 | Trả góp 0%, giá tốt',
        seoDescription:
          'Mua máy tính Apple Macbook Pro 14 inch 2021 - 2023 hiệu năng vô đối, giá tốt nhất tại FPT Shop. Thu cũ đổi mới, trợ giá lên đời hấp dẫn, trả góp 0%',
        seoKeyword:
          'macbook pro 14 inch, macbook pro 14, macbook pro 14 inch 2021, macbook pro 14 inch 2023',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 932,
        name: 'Asus TUF Gaming',
        nameAscii: 'asus-tuf-gaming',
        displayOrder: 8,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Asus TUF Gaming | Laptop bền chuẩn quân đội',
        seoDescription:
          'Mua laptop Asus TUF Gaming độ bền và hiệu năng lý tưởng trong tầm giá, sẽ đưa bạn đến gần hơn với giấc mơ chinh phục thế giới game thủ. Hỗ trợ trả góp 0%',
        seoKeyword: 'asus gaming, asus tuf gaming',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 939,
        name: 'HP Pavilion',
        nameAscii: 'hp-pavilion',
        displayOrder: 8,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'HP Pavilion | Laptop thiết kế đẹp, cấu hình cao',
        seoDescription:
          'Mua laptop HP giá tốt với 2 dòng nổi bật HP Pavilion 14, HP Pavilion 15. Giá ưu đãi, hỗ trợ trả góp 0% lãi suất, miễn phí giao hàng, thanh toán linh hoạt',
        seoKeyword: 'hp pavilion, hp pavilion 15',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 203,
        attributeCustomID: 695,
        name: 'SSD 512 GB',
        nameAscii: 'ssd-512-gb',
        displayOrder: 8,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Ổ cứng',
        attrCusGroupNameAscii: 'o-cung',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop ổ cứng SSD 512GB | Giá rẻ, trả góp 0%',
        seoDescription:
          'Mua laptop trang bị ổ cứng SSD 512GB nhanh và mượt, giá rẻ được nhiều khách hàng lựa chọn, miễn phí giao hàng, bảo hành chính hãng. Mua ngay!',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 205,
        attributeCustomID: 2230,
        name: 'CPU Intel Gen 13',
        nameAscii: 'cpu-intel-gen-13',
        displayOrder: 8,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Tính năng đặc biệt',
        attrCusGroupNameAscii: 'tinh-nang-dac-biet',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop Intel Gen 13 | Giá tốt, trả góp 0%',
        seoDescription:
          'Mua laptop trang bị CPU Intel Gen 13 thế hệ mới nhất, cấu hình mạnh mẽ, nhiều lựa chọn cho mọi phân khúc. Bảo hành chính hãng, giao hàng toàn quốc, hậu mãi chu đáo.',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 198,
        attributeCustomID: 676,
        name: 'Intel Core i7',
        nameAscii: 'intel-core-i7',
        displayOrder: 8,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'CPU',
        attrCusGroupNameAscii: 'cpu',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop Core i7 | Cấu hình mạnh mẽ, cân mọi tác vụ',
        seoDescription:
          'Mua laptop, máy tính xách tay trang bị CPU Core i7 với cấu hình vượt trội. Miễn phí giao hàng, bảo hành chính hãng, hỗ trợ trả góp 0% xét duyệt nhanh',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: 5,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 1906,
        name: 'MSI Modern',
        nameAscii: 'msi-modern',
        displayOrder: 8,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'MSI Modern | Laptop chính hãng, mẫu mới 2023',
        seoDescription:
          'Mua máy tính chơi game MSI Modern thiết kế thời thượng, trang bị các công nghệ mới nhất, phù hợp mọi nhu cầu. Trả góp 0%, bảo hành chính hãng 12 tháng',
        seoKeyword: 'MSI Modern',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 942,
        name: 'MacBook Pro 13',
        nameAscii: 'macbook-pro-13',
        displayOrder: 8,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle:
          'MacBook Pro 2020 13 inch chính hãng, trả góp 0%, quà tặng hấp dẫn',
        seoDescription:
          'Mua MacBook Pro 2020 13 inch chính hãng Apple có hỗ trợ trả góp 0% lãi suất, giảm giá hấp dẫn, thu cũ đổi mới trợ giá ngay 15%, giao tận nơi miễn phí trong 1h. Xem ngay tại FPTShop!',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 169,
        attributeCustomID: 569,
        name: 'Từ 15 - 20 triệu',
        nameAscii: 'tu-15-20-trieu',
        displayOrder: 8,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Mức giá',
        attrCusGroupNameAscii: 'muc-gia',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop từ 15 - 20 triệu | Chính hãng 100%, trả góp 0 đồng',
        seoDescription:
          'Mua laptop dưới 20 triệu từ các thương hiệu nổi tiếng với thiết kế hiện đại cho mọi nhu cầu sử dụng. Giá giảm cực sốc, bảo hành chính hãng, trả góp 0%',
        seoKeyword: 'laptop từ 15 - 20 triệu, laptop dưới 20 triệu',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 202,
        attributeCustomID: 692,
        name: 'Trên 15 inch',
        nameAscii: 'tren-15-inch',
        displayOrder: 8,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Màn hình',
        attrCusGroupNameAscii: 'man-hinh',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop 15.6 inch | Máy tính xách tay trên 15 inch giá rẻ',
        seoDescription:
          'Mua laptop 15.6 inch, laptop 16 inch, laptop 17 inch giá rẻ, ưu đãi tốt nhất. Trả góp 0% duyệt nhanh, miễn phí giao nhanh trong 1H, đổi trả linh hoạt',
        seoKeyword: 'laptop 15.6 inch, laptop 16 inch, laptop 17 inch',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 199,
        attributeCustomID: 684,
        name: '8 GB',
        nameAscii: '8-gb',
        displayOrder: 8,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'RAM',
        attrCusGroupNameAscii: 'ram',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop RAM 8GB | Mang lại hiệu năng mạnh mẽ',
        seoDescription:
          'Mua laptop RAM 8GB có hỗ trợ trả góp 0%, đa dạng mẫu mã, nhiều mức giá để lựa chọn, phù hợp mọi nhu cầu, bảo hành chính hãng, miễn phí giao nhanh toàn quốc',
        seoKeyword: 'laptop ram 8gb',
        urlThumb: null,
        attrCusGroupLevelOrder: 4,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 959,
        name: 'Acer Nitro',
        nameAscii: 'acer-nitro',
        displayOrder: 8,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Acer Nitro | Laptop Acer Gaming tầm trung',
        seoDescription:
          'Mua laptop Acer Nitro gaming tầm trung được trang bị đầy đủ công nghệ hiện đại nhất, đồng hành cùng mọi game thủ. Bảo hành chính hãng, trả góp 0%',
        seoKeyword: 'Acer Nitro, Acer gaming',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 935,
        name: 'Dell XPS',
        nameAscii: 'dell-xps',
        displayOrder: 8,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Dell XPS | Laptop cao cấp, trả góp 0%',
        seoDescription:
          'Mua laptop Dell XPS thiết kế đẹp, hiện đại, trọng lượng cực kỳ nhẹ. Cam kết chính hãng, hỗ trợ trả góp 0%, quà tặng hấp dẫn, giao nhanh trong 1 giờ',
        seoKeyword: 'dell xps, laptop dell xps',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 946,
        name: 'Lenovo ThinkBook',
        nameAscii: 'lenovo-thinkbook',
        displayOrder: 8,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Lenovo ThinkBook | Laptop văn phòng giá rẻ',
        seoDescription:
          'Mua laptop Lenovo ThinkBook là dòng máy tính chuyên nghiệp, chuẩn phong cách doanh nghiệp. Cam kết chính hãng, hỗ trợ trả góp 0%, bảo hành 2 năm',
        seoKeyword: 'lenovo thinkbook',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 947,
        name: 'Lenovo Legion Gaming',
        nameAscii: 'lenovo-legion-gaming',
        displayOrder: 7,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Lenovo Legion | Laptop gaming giá tốt, trả góp 0%',
        seoDescription:
          'Mua laptop gaming Lenovo Legion thiết kế cá tính, bền bỉ, cấu hình mạnh mẽ, khả năng chiến game tốt. Cam kết chính hãng, trả góp 0%, miễn phí giao hàng',
        seoKeyword: 'lenovo legion, lenovo legion gaming',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 936,
        name: 'Dell G Gaming',
        nameAscii: 'dell-g-gaming',
        displayOrder: 7,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Dell Gaming | Laptop chơi game cấu hình cao, bền bỉ',
        seoDescription:
          'Mua laptop Dell Gaming trang bị cấu hình cao, đáp ứng nhu cầu chơi game, giải trí cực tốt. Cam kết chính hãng, hỗ trợ trả góp 0%, giao nhanh trong 1 giờ',
        seoKeyword: 'dell gaming, laptop dell gaming',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 960,
        name: 'Acer Predator',
        nameAscii: 'acer-predator',
        displayOrder: 7,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Acer Predator | Laptop cho game thủ chuyên nghiệp',
        seoDescription:
          'Mua laptop gaming Acer Predator cao cấp trang bị cấu hình cực đỉnh làm hài lòng bất cứ game thủ nào. Hỗ trợ trả góp 0%, bảo hành chính hãng, giá ưu đãi',
        seoKeyword: 'Acer Predator',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 931,
        name: 'Asus ROG',
        nameAscii: 'asus-rog',
        displayOrder: 7,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Asus ROG | Dòng laptop gaming đỉnh cao',
        seoDescription:
          'Mua laptop gaming Asus Rog diện mạo cá tính, hệ thống làm mát thông minh, cấu hình mạnh mẽ, chiến game. Cam kết chính hãng, trả góp 0%, bảo hành 2 năm',
        seoKeyword: 'asus rog, asus rog strix',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 199,
        attributeCustomID: 685,
        name: '16 GB',
        nameAscii: '16-gb',
        displayOrder: 7,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'RAM',
        attrCusGroupNameAscii: 'ram',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop RAM 16GB | Ưu đãi lớn, giá rẻ, chính hãng 100%',
        seoDescription:
          'Mua laptop, máy tính xách tay trang bị RAM 16GB cho hiệu suất máy tính ấn tượng. Chính hãng 100%, hỗ trợ trả góp 0% duyệt nhanh, bảo hành chính hãng',
        seoKeyword: 'laptop ram 16gb',
        urlThumb: null,
        attrCusGroupLevelOrder: 4,
        id: 0,
      },
      {
        attributeID: 133,
        attributeCustomID: 1908,
        name: 'Thiết kế đồ họa',
        nameAscii: 'thiet-ke',
        displayOrder: 7,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Nhu cầu',
        attrCusGroupNameAscii: 'nhu-cau',
        urlPicture: '2022/9/19/637991745714018004_img-thietkedohoa.png',
        attrCusIsTop: true,
        seoTitle: 'Laptop đồ họa | Mẫu mới, giá rẻ, trả góp 0%',
        seoDescription:
          'Mua laptop thiết kế đồ họa chính hãng, giá rẻ với nhiều mẫu mã đa dạng, cấu hình cực khủng, mang lại trải nghiệm tốt nhất. Trả góp 0%, miễn phí giao hàng',
        seoKeyword:
          'laptop đồ họa, laptop thiết kế đồ họa, laptop đồ hoạ giá rẻ',
        urlThumb: '2022/9/19/637991745714018004_img-thietkedohoa.png',
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 169,
        attributeCustomID: 570,
        name: 'Từ 20 - 25 triệu',
        nameAscii: 'tu-20-25-trieu',
        displayOrder: 7,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Mức giá',
        attrCusGroupNameAscii: 'muc-gia',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop 20 triệu đến 25 triệu | Trả góp 0%, quà hấp dẫn',
        seoDescription:
          'Mua laptop giá từ 20 đến dưới 25 triệu cấu hình mạnh mẽ, cho mọi nhu cầu làm việc. Cam kết chính hãng, giá giảm sốc, trả góp 0% xét duyệt nhanh, giao trong 1H',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 941,
        name: 'MacBook Air',
        nameAscii: 'macbook-air',
        displayOrder: 7,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'MacBook Air 2020 - 2021 M1 chính hãng Apple, trả góp 0%',
        seoDescription:
          'Mua máy tính MacBook Air 2020 - 2021 M1 chính hãng Apple, giá tốt nhất tại FPTShop. Hỗ trợ trả góp 0% xét duyệt nhanh, bảo hành 1 năm chuẩn Apple, giao nhanh trong 1h, giảm giá cực khủng.',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 205,
        attributeCustomID: 2231,
        name: 'Card RTX Series 4000',
        nameAscii: 'card-rtx-series-4000',
        displayOrder: 7,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Tính năng đặc biệt',
        attrCusGroupNameAscii: 'tinh-nang-dac-biet',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop Geforce RTX 4000 series | Giá tốt nhất',
        seoDescription:
          'Mua laptop trang bị card Nvidia Geforce RTX 4000 series với hiệu năng đột phá cho trải nghiệm gaming đỉnh cao. Hàng chính hãng, nhiều ưu đãi, trả góp 0%. Mua ngay!',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 1907,
        name: 'MSI Prestige',
        nameAscii: 'msi-prestige',
        displayOrder: 7,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: '',
        seoDescription: '',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 203,
        attributeCustomID: 696,
        name: 'SSD 256 GB',
        nameAscii: 'ssd-256-gb',
        displayOrder: 7,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Ổ cứng',
        attrCusGroupNameAscii: 'o-cung',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop ổ cứng SSD 256GB | Trả góp 0%, ưu đãi lớn',
        seoDescription:
          'Mua laptop trang bị ổ cứng SSD 256GB chính hãng, có hỗ trợ trả góp 0%, bảo hành chính hãng 1+ năm, miễn phí giao hàng tới 63 tỉnh thành. Mua ngay!',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 940,
        name: 'HP Envy',
        nameAscii: 'hp-envy',
        displayOrder: 7,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'HP Envy | Laptop thiết kế đẹp, mỏng nhẹ, dễ mang theo',
        seoDescription:
          'Mua laptop HP Envy với sức cuốn hút đặc biệt, thiết kế đẹp, thời thượng. Hỗ trợ trả góp 0%, quà tặng hấp dẫn. Bảo hành chính hãng, giao nhanh trong 1H',
        seoKeyword: 'hp envy, hp envy 13',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 198,
        attributeCustomID: 680,
        name: 'AMD Ryzen 3',
        nameAscii: 'amd-ryzen-3',
        displayOrder: 6,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'CPU',
        attrCusGroupNameAscii: 'cpu',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop AMD Ryzen 3 | Chính hãng 100%, giá tốt nhất',
        seoDescription:
          'Mua laptop CPU AMD Ryzen 3 với nhiều mẫu mới, cập nhật liên tục, phục vụ tốt mọi nhu cầu. Miễn phí giao hàng, bảo hành chính hãng, hỗ trợ trả góp 0%',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: 5,
        id: 0,
      },
      {
        attributeID: 205,
        attributeCustomID: 700,
        name: 'Sử dụng tấm nền IPS',
        nameAscii: 'su-dung-tam-nen-ips',
        displayOrder: 6,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Tính năng đặc biệt',
        attrCusGroupNameAscii: 'tinh-nang-dac-biet',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop màn hình IPS | Trả góp 0%, giá tốt nhất',
        seoDescription:
          'Mua laptop có màn hình sử dụng tấm nền IPS cho hình ảnh sắc nét, mượt mà, mang lại trải nghiệm tuyệt vời. Trả góp 0%, miễn phí giao hàng, bảo hành chính hãng',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 169,
        attributeCustomID: 571,
        name: 'Trên 25 triệu',
        nameAscii: 'tren-25-trieu',
        displayOrder: 6,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Mức giá',
        attrCusGroupNameAscii: 'muc-gia',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop giá từ 25 triệu | Trả góp 0%, mẫu mới 2023',
        seoDescription:
          'Mua máy tính xách tay trên 25 triệu thiết kế cao cấp với cấu hình cực mạnh mẽ, phục vụ tốt mọi tác vụ. Cam kết chính hãng, trả góp 0%, giao nhanh trong 1H',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 1836,
        name: 'HP Victus',
        nameAscii: 'hp-victus',
        displayOrder: 6,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'HP Victus | Laptop chơi game giá rẻ, chính hãng',
        seoDescription:
          'Mua laptop gaming HP Victus thiết kế mỏng nhẹ, đẳng cấp cùng cấu hình mạnh mẽ, hiệu năng cực đỉnh chiến tốt các tựa game hot. Trả góp 0%, bảo hành 12 tháng',
        seoKeyword: 'hp victus',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 199,
        attributeCustomID: 686,
        name: '32 GB',
        nameAscii: '32-gb',
        displayOrder: 6,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'RAM',
        attrCusGroupNameAscii: 'ram',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop RAM 32GB | Cấu hình cao, xử lý tác vụ siêu nhanh',
        seoDescription:
          'Mua laptop RAM 32GB đa dạng mẫu mã, cấu hình cao cho mọi tác vụ. Hỗ trợ trả góp 0%, ưu đãi giảm giá hấp dẫn, miễn phí giao nhanh, bảo hành chính hãng',
        seoKeyword: 'laptop ram 32gb',
        urlThumb: null,
        attrCusGroupLevelOrder: 4,
        id: 0,
      },
      {
        attributeID: 133,
        attributeCustomID: 1568,
        name: 'Mỏng nhẹ',
        nameAscii: 'mong-nhe',
        displayOrder: 6,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Nhu cầu',
        attrCusGroupNameAscii: 'nhu-cau',
        urlPicture: '2022/9/19/637991744678844250_img-mongnhe.png',
        attrCusIsTop: true,
        seoTitle: 'Laptop mỏng nhẹ | Cấu hình mạnh, giá tốt',
        seoDescription:
          'Mua laptop mỏng nhẹ giá rẻ cho mọi đối tượng từ sinh viên, dân văn phòng đến đồ họa, gaming. Mẫu mới nhất, trả góp 0%, giao nhanh trong 1H, hậu mãi chu đáo',
        seoKeyword:
          'laptop mỏng nhẹ, laptop mỏng nhẹ giá rẻ, laptop văn phòng mỏng nhẹ',
        urlThumb: '2022/9/6/637980625377152654_Mỏng nhẹ.png',
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 1008,
        name: 'Lenovo Yoga',
        nameAscii: 'lenovo-yoga',
        displayOrder: 6,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop Lenovo Yoga | Giảm giá lớn, trả góp 0%',
        seoDescription:
          'Mua laptop Lenovo Yoga kiểu dáng khỏe khoắn, thiết kế gọn nhẹ, cấu hình mạnh mẽ. Cam kết chính hãng, trả góp 0%, miễn phí giao hàng, bảo hành 2 năm',
        seoKeyword: 'lenovo yoga',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 133,
        attributeCustomID: 1566,
        name: 'Doanh nhân',
        nameAscii: 'doanh-nhan',
        displayOrder: 5,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Nhu cầu',
        attrCusGroupNameAscii: 'nhu-cau',
        urlPicture: '2022/9/19/637991745141508369_img-doanhnhan.png',
        attrCusIsTop: true,
        seoTitle: 'Laptop doanh nhân | Cao cấp, sang trọng, mẫu mới nhất',
        seoDescription:
          'Mua laptop doanh nhân thiết kế sang trọng, mỏng nhẹ phù hợp di chuyển, chạy đa nhiệm tốt, hiệu suất ổn định. Mẫu mới nhất 2023, trả góp 0%, giao nhanh trong 1H',
        seoKeyword: '',
        urlThumb: '2022/9/19/637991745141508369_img-doanhnhan.png',
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 1837,
        name: 'HP Zbook',
        nameAscii: 'hp-zbook',
        displayOrder: 5,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: '',
        seoDescription: '',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 205,
        attributeCustomID: 701,
        name: 'Màn hình cảm ứng',
        nameAscii: 'man-hinh-cam-ung',
        displayOrder: 5,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Tính năng đặc biệt',
        attrCusGroupNameAscii: 'tinh-nang-dac-biet',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop màn hình cảm ứng | Mẫu mới 2023, trả góp 0%',
        seoDescription:
          'Mua laptop màn hình cảm ứng giá rẻ, mượt mà trên từng thao tác. Khuyến mại hấp dẫn, hỗ trợ trả góp 0%, bảo hành chính hãng, miễn phí giao nhanh trong 1H',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 198,
        attributeCustomID: 681,
        name: 'AMD Ryzen 5',
        nameAscii: 'amd-ryzen-5',
        displayOrder: 5,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'CPU',
        attrCusGroupNameAscii: 'cpu',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop AMD Ryzen 5 | Mẫu mới nhất 2023, giá ưu đãi',
        seoDescription:
          'Mua laptop, máy tính xách tay trang bị CPU AMD Ryzen 5 hiệu năng tốt, độ bền cao. Miễn phí giao hàng, bảo hành chính hãng, hỗ trợ trả góp 0% xét duyệt nhanh',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: 5,
        id: 0,
      },
      {
        attributeID: 203,
        attributeCustomID: 697,
        name: 'SSD 128 GB',
        nameAscii: 'ssd-128-gb',
        displayOrder: 5,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Ổ cứng',
        attrCusGroupNameAscii: 'o-cung',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop ổ cứng SSD 128GB | Giá rẻ, bảo hành chính hãng',
        seoDescription:
          'Mua laptop trang bị ổ cứng SSD 128GB giá rẻ, khuyến mãi hấp dẫn, có trả góp 0%, miễn phí giao hàng tới 63 tỉnh thành, bảo hành 1+ năm. Mua ngay!',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 198,
        attributeCustomID: 682,
        name: 'AMD Ryzen 7',
        nameAscii: 'amd-ryzen-7',
        displayOrder: 4,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'CPU',
        attrCusGroupNameAscii: 'cpu',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop AMD Ryzen 7 | Cấu hình cao, chiến game siêu mượt',
        seoDescription:
          'Mua laptop trang bị CPU AMD Ryzen 7 cấu hình khủng, hoạt động với hiệu suất vượt trội. Miễn phí giao hàng, bảo hành chính hãng, hỗ trợ trả góp 0% xét duyệt nhanh',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: 5,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 1838,
        name: 'HP ProBook',
        nameAscii: 'hp-probook',
        displayOrder: 4,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop HP ProBook | Trả góp 0%, thu cũ đổi mới',
        seoDescription:
          'Mua laptop HP ProBook thiết kế nhỏ gọn, hiện đại, hiệu năng được nâng cấp mạnh mẽ, bền bỉ và bảo mật cao. Bảo hành chính hãng, trả góp 0%, miễn phí giao hàng',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 198,
        attributeCustomID: 1513,
        name: 'AMD Ryzen 9',
        nameAscii: 'amd-ryzen-9',
        displayOrder: 3,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'CPU',
        attrCusGroupNameAscii: 'cpu',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop AMD Ryzen 9 | Cấu hình khủng, chính hãng 100%',
        seoDescription:
          'Mua laptop CPU AMD Ryzen 9 có cấu hình khủng, hoạt động với hiệu suất cao nhất, mạnh mẽ nhất. Miễn phí giao nhanh trong 1H, bảo hành chính hãng, trả góp 0%',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: 5,
        id: 0,
      },
      {
        attributeID: 283,
        attributeCustomID: 2212,
        name: 'HP Omen',
        nameAscii: 'hp-omen',
        displayOrder: 3,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'model series',
        attrCusGroupNameAscii: 'model-series',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'HP Omen | Laptop gaming chính hãng, trả góp 0%',
        seoDescription:
          'Mua laptop chơi game HP Omen chính hãng, hiệu năng ấn tượng, xử lý tốt các tác vụ gaming, đồ họa. Hỗ trợ trả góp 0%, mua online giao nhanh miễn phí.',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 200,
        attributeCustomID: 687,
        name: 'NVIDIA GeForce Series',
        nameAscii: 'nvidia-geforce-series',
        displayOrder: 1,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Card đồ họa',
        attrCusGroupNameAscii: 'card-do-hoa',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop NVIDIA GeForce Series | Chính hãng 100%',
        seoDescription:
          'Mua laptop trang bị card màn hình NVIDIA GeForce chính hãng 100%, khuyến mãi hấp hẫn, hỗ trợ trả góp 0%, bảo hành chính hãng, miễn phí giao hàng. Mua ngay!',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 205,
        attributeCustomID: 2232,
        name: 'Bảo vệ trẻ em',
        nameAscii: 'bao-ve-tre-em',
        displayOrder: 1,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Tính năng đặc biệt',
        attrCusGroupNameAscii: 'tinh-nang-dac-biet',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: '',
        seoDescription: '',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 205,
        attributeCustomID: 2233,
        name: 'CPU AMD',
        nameAscii: 'cpu-amd',
        displayOrder: 0,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Tính năng đặc biệt',
        attrCusGroupNameAscii: 'tinh-nang-dac-biet',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: '',
        seoDescription: '',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 205,
        attributeCustomID: 702,
        name: 'Windows bản quyền',
        nameAscii: 'windows-ban-quyen',
        displayOrder: 0,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Tính năng đặc biệt',
        attrCusGroupNameAscii: 'tinh-nang-dac-biet',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop có Windows bản quyền | Chính hãng 100%',
        seoDescription:
          'Mua laptop có Windows bản quyền hiệu năng ổn định, mẫu mới nhất 2023. Khuyến mại hấp dẫn, hỗ trợ trả góp 0%, bảo hành chính hãng, miễn phí giao nhanh trong 1H',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 205,
        attributeCustomID: 703,
        name: 'Mở khóa vân tay',
        nameAscii: 'mo-khoa-van-tay',
        displayOrder: 0,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Tính năng đặc biệt',
        attrCusGroupNameAscii: 'tinh-nang-dac-biet',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop có vân tay | Mẫu mới nhất 2023, giá tốt',
        seoDescription:
          'Mua laptop trang bị bảo mật vân tay, an toàn tuyệt đối, mở khóa nhanh chóng. Mẫu mới, bảo hành chính hãng, trả góp 0% xét duyệt nhanh, miễn phí giao hàng',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 200,
        attributeCustomID: 688,
        name: 'AMD Radeon Series',
        nameAscii: 'amd-radeon-series',
        displayOrder: 0,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Card đồ họa',
        attrCusGroupNameAscii: 'card-do-hoa',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop card màn hình AMD Radeon Series | Chính hãng',
        seoDescription:
          'Mua laptop trang bị card màn hình AMD Radeon Series nhiều mẫu mã, chất lượng tốt. Bảo hành chính hãng, giao nhanh toàn quốc trong 1H, trả góp 0%',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
      {
        attributeID: 200,
        attributeCustomID: 704,
        name: 'Card Onboard',
        nameAscii: 'card-onboard',
        displayOrder: 0,
        isDeleted: null,
        isAllowFilter: null,
        isShow: null,
        specLink: null,
        attrCusGroupName: 'Card đồ họa',
        attrCusGroupNameAscii: 'card-do-hoa',
        urlPicture: null,
        attrCusIsTop: false,
        seoTitle: 'Laptop card onboard | Giá rẻ, trả góp 0%',
        seoDescription:
          'Mua laptop card màn hình onboard với cấu hình mạnh mẽ, mẫu mã đa dạng cho mọi nhu cầu. Bảo hành chính hãng, giao hàng miễn phí, hỗ trợ trả góp 0%',
        seoKeyword: '',
        urlThumb: null,
        attrCusGroupLevelOrder: null,
        id: 0,
      },
    ],
  },
  attributeSpecItems: [
    {
      productID: 35588,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '13.3 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 39359,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '15.6 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 38369,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '14.2 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 41003,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '13.3 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 43706,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '14.0 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 39365,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '16.2 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 38365,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '16.2 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 43637,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '15.6 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 41001,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '13.6 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 40909,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '15.6 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 40913,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '16.1 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 40497,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '15.6 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 42428,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '15.6 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 42706,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '14.0 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 36480,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '13.3 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 42793,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '15.6 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 42780,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '16.0 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 41113,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '13.3 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 39197,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '14.0 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 40317,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '16.1 inch Chính:',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 39666,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '14.0 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 42029,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '15.6 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 35585,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '13.3 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 43747,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '16.1 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 39676,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '14.0 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 42794,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '14.0 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 42362,
      attributeID: 33,
      attributeName: 'Màn hình',
      specName: '15.6 inch',
      cssClass: 'icon-screen-size',
    },
    {
      productID: 42706,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'Core i3',
      cssClass: 'icon-cpu',
    },
    {
      productID: 43637,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'Core i7',
      cssClass: 'icon-cpu',
    },
    {
      productID: 41001,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'M2',
      cssClass: 'icon-cpu',
    },
    {
      productID: 38365,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'M1 Pro',
      cssClass: 'icon-cpu',
    },
    {
      productID: 35588,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'M1',
      cssClass: 'icon-cpu',
    },
    {
      productID: 43747,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'Ryzen 5',
      cssClass: 'icon-cpu',
    },
    {
      productID: 39359,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'Ryzen 7',
      cssClass: 'icon-cpu',
    },
    {
      productID: 39676,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'Core i5',
      cssClass: 'icon-cpu',
    },
    {
      productID: 42794,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'Ryzen 7',
      cssClass: 'icon-cpu',
    },
    {
      productID: 39197,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'Core i3',
      cssClass: 'icon-cpu',
    },
    {
      productID: 36480,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'M1',
      cssClass: 'icon-cpu',
    },
    {
      productID: 40913,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'Core i5',
      cssClass: 'icon-cpu',
    },
    {
      productID: 43706,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'Core i5',
      cssClass: 'icon-cpu',
    },
    {
      productID: 42780,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'Core i7',
      cssClass: 'icon-cpu',
    },
    {
      productID: 42362,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'Core i7',
      cssClass: 'icon-cpu',
    },
    {
      productID: 41003,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'M2',
      cssClass: 'icon-cpu',
    },
    {
      productID: 40497,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'Core i5',
      cssClass: 'icon-cpu',
    },
    {
      productID: 35585,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'M1',
      cssClass: 'icon-cpu',
    },
    {
      productID: 41113,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'M2',
      cssClass: 'icon-cpu',
    },
    {
      productID: 40317,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'Core i7',
      cssClass: 'icon-cpu',
    },
    {
      productID: 38369,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'M1 Pro',
      cssClass: 'icon-cpu',
    },
    {
      productID: 40909,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'Ryzen 7',
      cssClass: 'icon-cpu',
    },
    {
      productID: 42428,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'Core i7',
      cssClass: 'icon-cpu',
    },
    {
      productID: 42029,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'Core i5',
      cssClass: 'icon-cpu',
    },
    {
      productID: 42793,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'Ryzen 7',
      cssClass: 'icon-cpu',
    },
    {
      productID: 39365,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'M1 Pro',
      cssClass: 'icon-cpu',
    },
    {
      productID: 39666,
      attributeID: 34,
      attributeName: 'CPU',
      specName: 'Core i5',
      cssClass: 'icon-cpu',
    },
    {
      productID: 40497,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '8 GB (1 thanh 8 GB)',
      cssClass: 'icon-ram',
    },
    {
      productID: 42706,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '8 GB',
      cssClass: 'icon-ram',
    },
    {
      productID: 43637,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '16 GB (2 thanh 8 GB)',
      cssClass: 'icon-ram',
    },
    {
      productID: 38369,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '16 GB',
      cssClass: 'icon-ram',
    },
    {
      productID: 39365,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '32 GB',
      cssClass: 'icon-ram',
    },
    {
      productID: 35585,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '8 GB',
      cssClass: 'icon-ram',
    },
    {
      productID: 39666,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '8 GB (1 thanh 8 GB)',
      cssClass: 'icon-ram',
    },
    {
      productID: 42780,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '16 GB (2 thanh 8 GB)',
      cssClass: 'icon-ram',
    },
    {
      productID: 42362,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '8 GB (1 thanh 8 GB)',
      cssClass: 'icon-ram',
    },
    {
      productID: 41113,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '16 GB',
      cssClass: 'icon-ram',
    },
    {
      productID: 41003,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '8 GB',
      cssClass: 'icon-ram',
    },
    {
      productID: 38365,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '16 GB',
      cssClass: 'icon-ram',
    },
    {
      productID: 43706,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '16 GB (2 thanh 8 GB)',
      cssClass: 'icon-ram',
    },
    {
      productID: 39359,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '8 GB',
      cssClass: 'icon-ram',
    },
    {
      productID: 36480,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '16 GB',
      cssClass: 'icon-ram',
    },
    {
      productID: 40913,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '16 GB (2 thanh 8 GB)',
      cssClass: 'icon-ram',
    },
    {
      productID: 39676,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '8 GB',
      cssClass: 'icon-ram',
    },
    {
      productID: 40317,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '8 GB (2 thanh 4 GB)',
      cssClass: 'icon-ram',
    },
    {
      productID: 42793,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '8 GB (1 thanh 8 GB)',
      cssClass: 'icon-ram',
    },
    {
      productID: 35588,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '8 GB',
      cssClass: 'icon-ram',
    },
    {
      productID: 42794,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '8 GB (1 thanh 8 GB)',
      cssClass: 'icon-ram',
    },
    {
      productID: 42428,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '8 GB (2 thanh 4 GB)',
      cssClass: 'icon-ram',
    },
    {
      productID: 41001,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '8 GB',
      cssClass: 'icon-ram',
    },
    {
      productID: 42029,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '8 GB',
      cssClass: 'icon-ram',
    },
    {
      productID: 39197,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '4 GB',
      cssClass: 'icon-ram',
    },
    {
      productID: 40909,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '8 GB (1 thanh 8 GB)',
      cssClass: 'icon-ram',
    },
    {
      productID: 43747,
      attributeID: 35,
      attributeName: 'RAM',
      specName: '8 GB (1 thanh 8 GB)',
      cssClass: 'icon-ram',
    },
    {
      productID: 35585,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 256 GB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 41001,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 256 GB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 42428,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 512 GB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 42362,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 512 GB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 40317,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 512 GB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 42706,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 256 GB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 40497,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 512 GB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 43637,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 512 GB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 39676,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 512 GB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 35588,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 512 GB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 43706,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 512 GB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 41003,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 256 GB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 41113,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 256 GB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 40909,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 512 GB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 42029,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 512 GB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 39666,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 256 GB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 42793,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 512 GB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 42794,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 512 GB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 38369,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 1 TB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 36480,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 256 GB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 43747,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 512 GB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 39365,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 512 GB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 40913,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 1 TB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 38365,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 1 TB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 39359,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 128 GB; HDD 1000 GB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 42780,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 512 GB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 39197,
      attributeID: 27,
      attributeName: 'Ổ cứng',
      specName: 'SSD 128 GB',
      cssClass: 'icon-hdd-black',
    },
    {
      productID: 43747,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'NVIDIA GeForce RTX 3050 4GB',
      cssClass: 'icon-vga',
    },
    {
      productID: 39365,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'Apple M1 Pro',
      cssClass: 'icon-vga',
    },
    {
      productID: 41001,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'Apple M2',
      cssClass: 'icon-vga',
    },
    {
      productID: 40317,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'NVIDIA GeForce RTX 3050 Ti 4GB',
      cssClass: 'icon-vga',
    },
    {
      productID: 41003,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'Apple M2',
      cssClass: 'icon-vga',
    },
    {
      productID: 38369,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'Apple M1 Pro',
      cssClass: 'icon-vga',
    },
    {
      productID: 42794,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'NVIDIA GeForce RTX 3050 4GB',
      cssClass: 'icon-vga',
    },
    {
      productID: 39197,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'Intel UHD Graphics',
      cssClass: 'icon-vga',
    },
    {
      productID: 42029,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'NVIDIA GeForce RTX 3050 Ti 4GB',
      cssClass: 'icon-vga',
    },
    {
      productID: 39676,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'Intel Iris Xe Graphics',
      cssClass: 'icon-vga',
    },
    {
      productID: 42780,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'Intel Iris Xe Graphics',
      cssClass: 'icon-vga',
    },
    {
      productID: 36480,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'Apple M1',
      cssClass: 'icon-vga',
    },
    {
      productID: 40913,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'NVIDIA GeForce RTX 3050 Ti 4GB',
      cssClass: 'icon-vga',
    },
    {
      productID: 42362,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'NVIDIA GeForce RTX 3050 Ti 4GB',
      cssClass: 'icon-vga',
    },
    {
      productID: 42793,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'NVIDIA GeForce RTX 3060 6GB',
      cssClass: 'icon-vga',
    },
    {
      productID: 35588,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'Apple M1',
      cssClass: 'icon-vga',
    },
    {
      productID: 41113,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'Apple M2',
      cssClass: 'icon-vga',
    },
    {
      productID: 35585,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'Apple M1',
      cssClass: 'icon-vga',
    },
    {
      productID: 40497,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'NVIDIA GeForce RTX 3050 4GB',
      cssClass: 'icon-vga',
    },
    {
      productID: 43706,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'Intel Iris Xe Graphics',
      cssClass: 'icon-vga',
    },
    {
      productID: 39359,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'AMD Radeon Graphics',
      cssClass: 'icon-vga',
    },
    {
      productID: 39666,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'Intel HD Graphics',
      cssClass: 'icon-vga',
    },
    {
      productID: 42428,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'NVIDIA GeForce MX550 2GB',
      cssClass: 'icon-vga',
    },
    {
      productID: 43637,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'NVIDIA GeForce RTX 3060 6GB',
      cssClass: 'icon-vga',
    },
    {
      productID: 38365,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'Apple M1 Pro',
      cssClass: 'icon-vga',
    },
    {
      productID: 40909,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'NVIDIA GeForce RTX 3050 4GB',
      cssClass: 'icon-vga',
    },
    {
      productID: 42706,
      attributeID: 36,
      attributeName: 'Đồ họa',
      specName: 'Intel UHD Graphics',
      cssClass: 'icon-vga',
    },
    {
      productID: 43637,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '2.35 kg',
      cssClass: 'icon-weight',
    },
    {
      productID: 40909,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '2.2 kg',
      cssClass: 'icon-weight',
    },
    {
      productID: 42794,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '1600 g',
      cssClass: 'icon-weight',
    },
    {
      productID: 39359,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '1.66 kg',
      cssClass: 'icon-weight',
    },
    {
      productID: 42706,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '999 g',
      cssClass: 'icon-weight',
    },
    {
      productID: 41113,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '1.4 kg',
      cssClass: 'icon-weight',
    },
    {
      productID: 39676,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '1.35 kg',
      cssClass: 'icon-weight',
    },
    {
      productID: 40497,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '2.0 kg',
      cssClass: 'icon-weight',
    },
    {
      productID: 41001,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '1.24',
      cssClass: 'icon-weight',
    },
    {
      productID: 42793,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '2.20 kg',
      cssClass: 'icon-weight',
    },
    {
      productID: 43706,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '1.36 kg',
      cssClass: 'icon-weight',
    },
    {
      productID: 38365,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '2.129 kg',
      cssClass: 'icon-weight',
    },
    {
      productID: 36480,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '1.29 kg',
      cssClass: 'icon-weight',
    },
    {
      productID: 38369,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '1.604 kg',
      cssClass: 'icon-weight',
    },
    {
      productID: 39365,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '2.129 kg',
      cssClass: 'icon-weight',
    },
    {
      productID: 42029,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '2 kg',
      cssClass: 'icon-weight',
    },
    {
      productID: 41003,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '1.4 kg',
      cssClass: 'icon-weight',
    },
    {
      productID: 39666,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '1.4 kg',
      cssClass: 'icon-weight',
    },
    {
      productID: 40913,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '2.3 kg',
      cssClass: 'icon-weight',
    },
    {
      productID: 35588,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '1.4 kg',
      cssClass: 'icon-weight',
    },
    {
      productID: 43747,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '2.4 kg',
      cssClass: 'icon-weight',
    },
    {
      productID: 42428,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '1.733 kg',
      cssClass: 'icon-weight',
    },
    {
      productID: 42362,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '2.2 kg',
      cssClass: 'icon-weight',
    },
    {
      productID: 42780,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '2.25 kg',
      cssClass: 'icon-weight',
    },
    {
      productID: 39197,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '1.5 kg',
      cssClass: 'icon-weight',
    },
    {
      productID: 35585,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '1.29 kg',
      cssClass: 'icon-weight',
    },
    {
      productID: 40317,
      attributeID: 29,
      attributeName: 'Trọng lượng',
      specName: '2.46 kg',
      cssClass: 'icon-weight',
    },
  ],
  promotionItems: [
    {
      sku: '00725093',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00725102',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00731891',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00777785',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00777788',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00782950',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00785469',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00785500',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00791171',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00791281',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00799905',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00800822',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00814963',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00815324',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00818144',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00818154',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00819893',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00823171',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00824251',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00825046',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00832764',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00833353',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00833413',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00833414',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00857687',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00858239',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00859672',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00859672',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00858239',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00857687',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00833414',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00833413',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00833353',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00832764',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00825046',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00824251',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00823171',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00819893',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00818154',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00818144',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00815324',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00814963',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00800822',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00799905',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00791281',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00791171',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00785500',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00785469',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00782950',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00777788',
      promotionName: 'Giảm đến 160.000đ qua VNPAY-QR.',
      urlPicture: '2022/10/1/638002156152811193_Logo-VNPAYQR.png',
      displayOrder: 5,
      id: 799,
    },
    {
      sku: '00777785',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00731891',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00725102',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00725093',
      promotionName: 'Tặng 500K mua robot hút bụi/máy lọc nước',
      urlPicture: '2023/3/13/638143263918283970_PMH-02-01.jpg',
      displayOrder: 5,
      id: 783,
    },
    {
      sku: '00725093',
      promotionName: 'Giảm đến 1 triệu khi mở thẻ TPBANK EVO',
      urlPicture: '2022/11/2/638029984985744443_tpbank-icon.png',
      displayOrder: 2,
      id: 734,
    },
    {
      sku: '00725102',
      promotionName: 'Giảm đến 1 triệu khi mở thẻ TPBANK EVO',
      urlPicture: '2022/11/2/638029984985744443_tpbank-icon.png',
      displayOrder: 2,
      id: 734,
    },
    {
      sku: '00731891',
      promotionName: 'Giảm đến 1 triệu khi mở thẻ TPBANK EVO',
      urlPicture: '2022/11/2/638029984985744443_tpbank-icon.png',
      displayOrder: 2,
      id: 734,
    },
    {
      sku: '00777785',
      promotionName: 'Giảm đến 1 triệu khi mở thẻ TPBANK EVO',
      urlPicture: '2022/11/2/638029984985744443_tpbank-icon.png',
      displayOrder: 2,
      id: 734,
    },
    {
      sku: '00777788',
      promotionName: 'Giảm đến 1 triệu khi mở thẻ TPBANK EVO',
      urlPicture: '2022/11/2/638029984985744443_tpbank-icon.png',
      displayOrder: 2,
      id: 734,
    },
    {
      sku: '00782950',
      promotionName: 'Giảm ngay 600.000đ khi mở thẻ TPBANK EVO',
      urlPicture:
        '2022/4/5/637847745475943329_637846629514363952_ic-tp-bank.jpg',
      displayOrder: 2,
      id: 600,
    },
    {
      sku: '00785469',
      promotionName: 'Giảm ngay 600.000đ khi mở thẻ TPBANK EVO',
      urlPicture:
        '2022/4/5/637847745475943329_637846629514363952_ic-tp-bank.jpg',
      displayOrder: 2,
      id: 600,
    },
    {
      sku: '00785500',
      promotionName: 'Giảm đến 1 triệu khi mở thẻ TPBANK EVO',
      urlPicture: '2022/11/2/638029984985744443_tpbank-icon.png',
      displayOrder: 2,
      id: 734,
    },
    {
      sku: '00791171',
      promotionName: 'Giảm ngay 600.000đ khi mở thẻ TPBANK EVO',
      urlPicture:
        '2022/4/5/637847745475943329_637846629514363952_ic-tp-bank.jpg',
      displayOrder: 2,
      id: 600,
    },
    {
      sku: '00791281',
      promotionName: 'Giảm ngay 600.000đ khi mở thẻ TPBANK EVO',
      urlPicture:
        '2022/4/5/637847745475943329_637846629514363952_ic-tp-bank.jpg',
      displayOrder: 2,
      id: 600,
    },
    {
      sku: '00799905',
      promotionName: 'Giảm ngay 600.000đ khi mở thẻ TPBANK EVO',
      urlPicture:
        '2022/4/5/637847745475943329_637846629514363952_ic-tp-bank.jpg',
      displayOrder: 2,
      id: 600,
    },
    {
      sku: '00800822',
      promotionName: 'Giảm ngay 600.000đ khi mở thẻ TPBANK EVO',
      urlPicture:
        '2022/4/5/637847745475943329_637846629514363952_ic-tp-bank.jpg',
      displayOrder: 2,
      id: 600,
    },
    {
      sku: '00814963',
      promotionName: 'Giảm ngay 600.000đ khi mở thẻ TPBANK EVO',
      urlPicture:
        '2022/4/5/637847745475943329_637846629514363952_ic-tp-bank.jpg',
      displayOrder: 2,
      id: 600,
    },
    {
      sku: '00815324',
      promotionName: 'Giảm ngay 600.000đ khi mở thẻ TPBANK EVO',
      urlPicture:
        '2022/4/5/637847745475943329_637846629514363952_ic-tp-bank.jpg',
      displayOrder: 2,
      id: 600,
    },
    {
      sku: '00818144',
      promotionName: 'Giảm đến 1 triệu khi mở thẻ TPBANK EVO',
      urlPicture: '2022/11/2/638029984985744443_tpbank-icon.png',
      displayOrder: 2,
      id: 734,
    },
    {
      sku: '00818154',
      promotionName: 'Giảm đến 1 triệu khi mở thẻ TPBANK EVO',
      urlPicture: '2022/11/2/638029984985744443_tpbank-icon.png',
      displayOrder: 2,
      id: 734,
    },
    {
      sku: '00819893',
      promotionName: 'Giảm đến 1 triệu khi mở thẻ TPBANK EVO',
      urlPicture: '2022/11/2/638029984985744443_tpbank-icon.png',
      displayOrder: 2,
      id: 734,
    },
    {
      sku: '00823171',
      promotionName: 'Giảm ngay 600.000đ khi mở thẻ TPBANK EVO',
      urlPicture:
        '2022/4/5/637847745475943329_637846629514363952_ic-tp-bank.jpg',
      displayOrder: 2,
      id: 600,
    },
    {
      sku: '00824251',
      promotionName: 'Giảm ngay 600.000đ khi mở thẻ TPBANK EVO',
      urlPicture:
        '2022/4/5/637847745475943329_637846629514363952_ic-tp-bank.jpg',
      displayOrder: 2,
      id: 600,
    },
    {
      sku: '00825046',
      promotionName: 'Giảm ngay 600.000đ khi mở thẻ TPBANK EVO',
      urlPicture:
        '2022/4/5/637847745475943329_637846629514363952_ic-tp-bank.jpg',
      displayOrder: 2,
      id: 600,
    },
    {
      sku: '00832764',
      promotionName: 'Giảm ngay 600.000đ khi mở thẻ TPBANK EVO',
      urlPicture:
        '2022/4/5/637847745475943329_637846629514363952_ic-tp-bank.jpg',
      displayOrder: 2,
      id: 600,
    },
    {
      sku: '00833353',
      promotionName: 'Giảm ngay 600.000đ khi mở thẻ TPBANK EVO',
      urlPicture:
        '2022/4/5/637847745475943329_637846629514363952_ic-tp-bank.jpg',
      displayOrder: 2,
      id: 600,
    },
    {
      sku: '00833413',
      promotionName: 'Giảm ngay 600.000đ khi mở thẻ TPBANK EVO',
      urlPicture:
        '2022/4/5/637847745475943329_637846629514363952_ic-tp-bank.jpg',
      displayOrder: 2,
      id: 600,
    },
    {
      sku: '00833414',
      promotionName: 'Giảm ngay 600.000đ khi mở thẻ TPBANK EVO',
      urlPicture:
        '2022/4/5/637847745475943329_637846629514363952_ic-tp-bank.jpg',
      displayOrder: 2,
      id: 600,
    },
    {
      sku: '00857687',
      promotionName: 'Giảm ngay 600.000đ khi mở thẻ TPBANK EVO',
      urlPicture:
        '2022/4/5/637847745475943329_637846629514363952_ic-tp-bank.jpg',
      displayOrder: 2,
      id: 600,
    },
    {
      sku: '00858239',
      promotionName: 'Giảm ngay 600.000đ khi mở thẻ TPBANK EVO',
      urlPicture:
        '2022/4/5/637847745475943329_637846629514363952_ic-tp-bank.jpg',
      displayOrder: 2,
      id: 600,
    },
    {
      sku: '00859672',
      promotionName: 'Giảm ngay 600.000đ khi mở thẻ TPBANK EVO',
      urlPicture:
        '2022/4/5/637847745475943329_637846629514363952_ic-tp-bank.jpg',
      displayOrder: 2,
      id: 600,
    },
    {
      sku: '00859672',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00858239',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00857687',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00833414',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00833413',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00833353',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00832764',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00825046',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00824251',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00823171',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00819893',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00818154',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00818144',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00815324',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00814963',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00800822',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00799905',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00791281',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00791171',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00785500',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00785469',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00782950',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00777788',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00777785',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00731891',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00725102',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
    {
      sku: '00725093',
      promotionName: 'Giảm ngay 5% tối đa 500.000Đ qua Kredivo',
      urlPicture: '2023/2/1/638108715966954351_download.png',
      displayOrder: 1,
      id: 763,
    },
  ],
  metaTagsItem: {
    heading: 'Laptop',
    title: 'Laptop | Máy tính xách tay giá rẻ, mẫu mới nhất 2023',
    description:
      'Mua laptop giá rẻ, máy tính xách tay chính hãng 100%, luôn cập nhật mẫu mới. Bảo hành hậu mãi chu đáo, trả góp 0%, miễn phí giao hàng, đổi trả linh hoạt',
    keywords: 'laptop, máy tính xách tay, laptop giá rẻ',
    canonicalUrl: 'https://fptshop.com.vn/may-tinh-xach-tay',
    metaImage:
      'https://images.fpt.shop/unsafe/fit-in/1200x628/filters:quality(90):fill(white)/fptshop.com.vn/Uploads/Originals/2022/11/7/638034393162095192_NF_1200x628-Laptop.jpg',
  },
  productTypeItem: {
    name: 'Máy tính xách tay',
    nameAscii: 'may-tinh-xach-tay',
    id: 3,
  },
  brandOnlyId: null,
  brandOnlyName: null,
  brandOnlyAscii: null,
  isCategoryPage: false,
};
